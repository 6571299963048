import React, { useState } from 'react';
import { Modal, Box, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface DocumentViewerModalProps {
  open: boolean;
  onClose: () => void;
  documentUrl: string | null;
  title: string;
}

const DocumentViewerModal: React.FC<DocumentViewerModalProps> = ({
  open,
  onClose,
  documentUrl,
  title
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLoad: React.ReactEventHandler<HTMLIFrameElement> = () => {
    setIsLoading(false);
  };

  if (!documentUrl) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="document-viewer-modal"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '95%' : '75%',
          height: isMobile ? '90%' : '80%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1,
          p: isMobile ? 1 : 2,
          outline: 'none',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: isMobile ? 1 : 2,
            p: isMobile ? 1 : 0
          }}
        >
          <h2 style={{ 
            margin: 0,
            fontSize: isMobile ? '1.25rem' : '1.5rem'
          }}>
            {title}
          </h2>
          <IconButton 
            onClick={onClose} 
            size={isMobile ? "small" : "medium"}
            sx={{
              padding: isMobile ? '4px' : '8px',
            }}
          >
            <CloseIcon fontSize={isMobile ? "small" : "medium"} />
          </IconButton>
        </Box>
        
        <Box
          sx={{
            flex: 1,
            position: 'relative',
            width: '100%',
            overflow: 'hidden'
          }}
        >
          {isLoading && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1
              }}
            >
              <CircularProgress 
                size={isMobile ? 40 : 56} 
                sx={{ color: 'primary.main' }} 
              />
            </Box>
          )}
          
          <iframe
            src={documentUrl}
            onLoad={handleLoad}
            title={title}
            style={{
              width: '100%',
              height: '100%',
              border: 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 0
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default DocumentViewerModal;