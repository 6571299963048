import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import EmailIcon from '@mui/icons-material/Email';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

const Pricing: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = () => {
      const userData = localStorage.getItem('userData');
      setIsLoggedIn(!!userData);
    };

    checkLoginStatus();
  }, []);

  const handlePremiumClick = () => {
    if (isLoggedIn) {
      navigate('/profile', { 
        state: { 
          openMembershipTab: true,
          tabIndex: 3
        } 
      });
    } else {
      navigate('/login', { state: { redirectTo: '/profile' } });
    }
  };

  const handleTrialClick = () => {
    navigate('/signup');
  };

  const handleQuoteClick = () => {
    navigate('/quote-request');
  };

  const cardStyles = (index: number) => ({
    p: { xs: 3, sm: 4 },
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: { xs: 2, md: 4 },
    position: 'relative',
    backgroundColor: 'white',
    boxShadow: hoveredCard === index
      ? '0px 8px 24px rgba(0, 0, 0, 0.15)'
      : '0px 4px 12px rgba(0, 0, 0, 0.1)',
    mb: { xs: 2, md: 0 },
    transition: 'all 0.3s ease',
    transform: hoveredCard === index ? 'translateY(-8px)' : 'none',
    overflow: 'hidden',
    '&::before': hoveredCard === index ? {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '5px',
      background: 'linear-gradient(90deg, #8BC34A, #4CAF50, #2196F3)',
      borderRadius: '0 0 4px 4px',
      zIndex: 1,
    } : {},
    '&::after': hoveredCard === index ? {
      content: '""',
      position: 'absolute',
      borderRadius: { xs: 2, md: 4 },
      borderImage: 'linear-gradient(to right, #8BC34A, #4CAF50, #2196F3)',
      pointerEvents: 'none',
      zIndex: 1,
    } : {},
  });

  const buttonStyles = {
    mt: 'auto',
    color: '#fff',
    borderRadius: 50,
    px: { xs: 2, md: 2 },
    py: 1.5,
    textTransform: 'none',
    fontFamily: '"Poppins", sans-serif',
    fontSize: { xs: '0.875rem', md: '1rem' },
    width: '250px',
    minHeight: '48px',
    transition: 'transform 0.2s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    }
  };

  const commonTypographyStyles = {
    fontFamily: '"Poppins", "Nunito", "Roboto", sans-serif'
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      position: 'relative'
    }}>
      <Navbar currentPage="pricing" />

      <Box
        className="page-bg"
        sx={{
          flexGrow: 1,
          width: '100%',
          pt: { xs: '84px', md: '100px' },
          pb: { xs: '40px', md: '60px' },
          px: { xs: 2, sm: 3, md: 4 },
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mb: { xs: 3, md: 5 }
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            align="center"
            sx={{
              ...commonTypographyStyles,
              color: '#2196F3',
              fontWeight: 'bold',
              mt: 6,
              mb: { xs: 3, md: 5 },
              fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }
            }}
          >
            Our pricing plans
          </Typography>

          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            justifyContent="center"
            sx={{ px: { xs: 0, sm: 2 } }}
          >
            <Grid item xs={12} sm={10} md={4}>
              <Paper
                elevation={3}
                sx={cardStyles(0)}
                onMouseEnter={() => setHoveredCard(0)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  align="center"
                  sx={{
                    ...commonTypographyStyles,
                    fontWeight: 'bold',
                    mb: 2,
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  Free Trial
                </Typography>

                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    ...commonTypographyStyles,
                    mb: { xs: 2, md: 3 },
                    color: '#666',
                    fontSize: { xs: '0.875rem', md: '1rem' }
                  }}
                >
                  30-day trial, no credit card required
                </Typography>

                <Box sx={{
                  width: '100%',
                  mb: { xs: 3, md: 4 },
                  px: { xs: 2, md: 0 }
                }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
                    <CheckCircleIcon sx={{ color: '#8BC34A', mr: 1 }} />
                    <Typography variant="body1" sx={commonTypographyStyles}>
                      All features included
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <CheckCircleIcon sx={{ color: '#FFC107', mr: 1 }} />
                    <Typography variant="body1" sx={commonTypographyStyles}>
                      Test for 30 days
                    </Typography>
                  </Box>
                </Box>

                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  className='btn-primary font-600'
                  onClick={handleTrialClick}
                  sx={buttonStyles}
                >
                  Get 30-Day Free Trial
                </Button>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={10} md={4}>
              <Paper
                elevation={3}
                sx={cardStyles(1)}
                onMouseEnter={() => setHoveredCard(1)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  align="center"
                  sx={{
                    ...commonTypographyStyles,
                    fontWeight: 'bold',
                    mb: 2,
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  Premium Membership
                </Typography>

                <Box sx={{
                  width: '100%',
                  mb: { xs: 3, md: 4 },
                  px: { xs: 2, md: 0 }
                }}>
                  {[
                    'Monthly: Enjoy uninterrupted learning.',
                    'Quarterly: Save 10% with a 3-month plan.',
                    'Yearly: Save 20% with the best value plan.'
                  ].map((text, index) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }} key={index}>
                      <CheckCircleIcon sx={{ color: '#8BC34A', mr: 1 }} />
                      <Typography
                        variant="body1"
                        sx={{
                          ...commonTypographyStyles,
                          fontSize: { xs: '0.875rem', md: '1rem' }
                        }}
                      >
                        {text}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  className='btn-primary font-600'
                  onClick={handlePremiumClick}
                  sx={buttonStyles}
                >
                  Get Membership
                </Button>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={10} md={4}>
              <Paper
                elevation={3}
                sx={cardStyles(2)}
                onMouseEnter={() => setHoveredCard(2)}
                onMouseLeave={() => setHoveredCard(null)}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  align="center"
                  sx={{
                    ...commonTypographyStyles,
                    fontWeight: 'bold',
                    mb: 2,
                    fontSize: { xs: '1.25rem', md: '1.5rem' }
                  }}
                >
                  Teachers, Schools and Districts
                </Typography>

                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    ...commonTypographyStyles,
                    color: '#666',
                    pb: 1,
                    fontSize: { xs: '0.875rem', md: '1rem' }
                  }}
                >
                  Request a custom quote
                </Typography>

                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    ...commonTypographyStyles,
                    fontWeight: 'bold',
                    color: '#1A1A3A',
                    pb: 1,
                    fontSize: { xs: '0.875rem', md: '1rem' }
                  }}
                >
                  Special plans for teachers, schools and districts
                </Typography>

                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    ...commonTypographyStyles,
                    mb: 5,
                    color: '#666',
                    fontSize: { xs: '0.875rem', md: '1rem' }
                  }}
                >
                  Click on the button to submit a quote request or email us!
                </Typography>

                <Box sx={{ flexGrow: 1 }} />

                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                  mt: 'auto'
                }}>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                    <EmailIcon sx={{ mr: 1, color: '#3F51B5' }} />
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#3F51B5',
                        fontWeight: 'medium',
                        fontSize: { xs: '0.875rem', md: '1rem' }
                      }}
                    >
                      sales@pragnatyx.com
                    </Typography>
                  </Box>

                  <Button
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    className='btn-primary font-600'
                    onClick={handleQuoteClick}
                    sx={buttonStyles}
                  >
                    Request a quote
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
}

export default Pricing;