import { cookieInfoType } from "../../types/globaltypes";
import axios from "axios";

const cookiePreferences = async (data: cookieInfoType,token:string,apiUrl:string) => {
    try {
      const response = await axios.post(
        `${apiUrl}createProfile`,
        data,
        {
          method: 'POST',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`
          },
          timeout:30000
        },
      );
      const apiData = response;
      return apiData.data;
    } catch (error) {
      return 'Error'
    }
  };

export default cookiePreferences;

export const cookiePreferencesWebsite = async (data: cookieInfoType, token: string, apiUrl: string) => {
  try {
    const batchData = [data];

    const response = await axios.post(
      `${apiUrl}createProfileBatch`,
      batchData,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        timeout: 30000
      },
    );
    return response.data;
  } catch (error) {
    console.error('Profile creation error:', error);
    return 'Error'
  }
};