import React, { useState } from 'react';
import {
    Box,
    Container,
    Typography,
    Paper,
    TextField,
    Button,
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Snackbar,
    Alert,
    FormHelperText,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendIcon from '@mui/icons-material/Send';
import SchoolIcon from '@mui/icons-material/School';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';

interface FormData {
    name: string;
    email: string;
    phone: string;
    schoolName: string;
    role: string;
    schoolType: string;
    studentCount: string;
    message: string;
}

const QuoteRequest: React.FC = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        phone: '',
        schoolName: '',
        role: '',
        schoolType: '',
        studentCount: '',
        message: '',
    });

    const [errors, setErrors] = useState<Partial<FormData>>({});
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (
        e:
            | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent
    ) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name as string]: value,
        });

        if (errors[name as keyof FormData]) {
            setErrors({
                ...errors,
                [name as string]: '',
            });
        }
    };

    const validateForm = (): boolean => {
        const newErrors: Partial<FormData> = {};

        if (!formData.name.trim()) newErrors.name = 'Name is required';

        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }

        if (!formData.schoolName.trim())
            newErrors.schoolName = 'School name is required';
        if (!formData.role) newErrors.role = 'Your role is required';
        if (!formData.schoolType) newErrors.schoolType = 'School type is required';
        if (!formData.studentCount)
            newErrors.studentCount = 'Student count is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (validateForm()) {
            console.log('Form data submitted:', formData);

            setSubmitted(true);

            setFormData({
                name: '',
                email: '',
                phone: '',
                schoolName: '',
                role: '',
                schoolType: '',
                studentCount: '',
                message: '',
            });

            setTimeout(() => {
                navigate('/pricing');
            }, 3000);
        }
    };

    const formFieldStyles = {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#E0E0E0',
            },
            '&:hover fieldset': {
                borderColor: '#2196F3',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#2196F3',
            },
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#2196F3',
        },
    };

    const handleBack = () => {
        navigate('/pricing');
    };

    const handleCloseSnackbar = () => {
        setSubmitted(false);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar currentPage="pricing" />

            <Box
                className="page-bg"
                sx={{
                    flex: 1,
                    mt: '64px',
                    py: { xs: 4, md: 6 },
                    px: 2,
                    minHeight: 'calc(100vh - 64px - 200px)',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Container maxWidth="md">
                    <Box sx={{ mb: 3, position: 'sticky', top: '72px' }}>
                        <Button
                            startIcon={<ArrowBackIcon />}
                            onClick={handleBack}
                            sx={{
                                color: '#2196F3',
                                textTransform: 'none',
                                fontFamily: '"Poppins", sans-serif',
                                fontWeight: 600,
                                '&:hover': {
                                    backgroundColor: 'rgba(33, 150, 243, 0.08)',
                                },
                            }}
                        >
                            Back to Pricing
                        </Button>
                    </Box>

                    <Paper
                        elevation={3}
                        sx={{
                            p: { xs: 3, md: 5 },
                            borderRadius: 4,
                            backgroundColor: 'white',
                            mb: 4,
                        }}
                    >
                        <Box sx={{ mb: 4, textAlign: 'center' }}>
                            <SchoolIcon
                                sx={{
                                    color: '#2196F3',
                                    fontSize: 60,
                                    mb: 2,
                                }}
                            />

                            <Typography
                                variant="h4"
                                component="h1"
                                sx={{
                                    fontFamily: '"Poppins", sans-serif',
                                    color: '#2196F3',
                                    fontWeight: 'bold',
                                    mb: 2,
                                    fontSize: { xs: '1.75rem', md: '2.25rem' },
                                }}
                            >
                                Request Your Personalized Quote
                            </Typography>

                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: '"Poppins", sans-serif',
                                        color: '#666',
                                        mb: 2,
                                        maxWidth: '600px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Fill in the details below and receive a proposal within 24
                                    business hours
                                </Typography>
                            </Box>
                        </Box>

                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Your Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        error={!!errors.name}
                                        helperText={errors.name}
                                        required
                                        variant="outlined"
                                        sx={formFieldStyles}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Email Address"
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        error={!!errors.email}
                                        helperText={errors.email}
                                        required
                                        variant="outlined"
                                        sx={formFieldStyles}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="Phone Number"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        variant="outlined"
                                        sx={formFieldStyles}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        label="School/District Name"
                                        name="schoolName"
                                        value={formData.schoolName}
                                        onChange={handleInputChange}
                                        error={!!errors.schoolName}
                                        helperText={errors.schoolName}
                                        required
                                        variant="outlined"
                                        sx={formFieldStyles}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl
                                        fullWidth
                                        error={!!errors.role}
                                        required
                                        sx={formFieldStyles}
                                    >
                                        <InputLabel>Your Role</InputLabel>
                                        <Select
                                            name="role"
                                            value={formData.role}
                                            label="Your Role"
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value="Teacher">Teacher</MenuItem>
                                            <MenuItem value="Principal">Principal</MenuItem>
                                            <MenuItem value="IT Administrator">
                                                IT Administrator
                                            </MenuItem>
                                            <MenuItem value="Curriculum Director">
                                                Curriculum Director
                                            </MenuItem>
                                            <MenuItem value="Superintendent">Superintendent</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                        {errors.role && (
                                            <FormHelperText>{errors.role}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl
                                        fullWidth
                                        error={!!errors.schoolType}
                                        required
                                        sx={formFieldStyles}
                                    >
                                        <InputLabel>School Type</InputLabel>
                                        <Select
                                            name="schoolType"
                                            value={formData.schoolType}
                                            label="School Type"
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value="Elementary School">
                                                Elementary School
                                            </MenuItem>
                                            <MenuItem value="Middle School">Middle School</MenuItem>
                                            <MenuItem value="High School">High School</MenuItem>
                                            <MenuItem value="K-12 School">K-12 School</MenuItem>
                                            <MenuItem value="School District">
                                                School District
                                            </MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                        {errors.schoolType && (
                                            <FormHelperText>{errors.schoolType}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <FormControl
                                        fullWidth
                                        error={!!errors.studentCount}
                                        required
                                        sx={formFieldStyles}
                                    >
                                        <InputLabel>Number of Students</InputLabel>
                                        <Select
                                            name="studentCount"
                                            value={formData.studentCount}
                                            label="Number of Students"
                                            onChange={handleInputChange}
                                        >
                                            <MenuItem value="1-50">1-50</MenuItem>
                                            <MenuItem value="51-100">51-100</MenuItem>
                                            <MenuItem value="101-500">101-500</MenuItem>
                                            <MenuItem value="501-1000">501-1000</MenuItem>
                                            <MenuItem value="1001-5000">1001-5000</MenuItem>
                                            <MenuItem value="5000+">5000+</MenuItem>
                                        </Select>
                                        {errors.studentCount && (
                                            <FormHelperText>{errors.studentCount}</FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Additional Information (optional)"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        placeholder="Please let us know about any specific requirements or questions you have."
                                        sx={formFieldStyles}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}
                                >
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className="btn btn-primary btn-member font-600"
                                        endIcon={<SendIcon />}
                                        sx={{
                                            mt: 'auto',
                                            color: '#fff',
                                            borderRadius: 50,
                                            px: 4,
                                            py: 1.5,
                                            textTransform: 'none',
                                            fontFamily: '"Poppins", sans-serif',
                                            minWidth: '200px',
                                        }}
                                    >
                                        Submit Request
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Container>
            </Box>

            <Snackbar
                open={submitted}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleCloseSnackbar}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Your quote request has been submitted successfully! We'll contact you
                    shortly.
                </Alert>
            </Snackbar>

            <Footer />
        </Box>
    );
};

export default QuoteRequest;
