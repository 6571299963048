import React, { useState, ChangeEvent, FormEvent } from 'react';
import {
  TextField,
  Button,
  CardContent,
  Typography,
  Alert,
  CircularProgress,
  Box,
  AlertColor,
  Paper,
  Container,
  Snackbar,
  IconButton,
  InputAdornment,
  createTheme,
  ThemeProvider,
  Grid
} from '@mui/material';
import {
  Add as AddIcon,
  Person as PersonIcon,
  VpnKey as VpnKeyIcon,
  Email as EmailIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Phone as PhoneIcon
} from '@mui/icons-material';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { styled } from '@mui/material/styles';
import { environment } from '../../../environments/environment';
import axios from 'axios';
import CustomModal from '../../components/CustomModal';

const professionalTheme = createTheme({
  palette: {
    primary: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#66bb6a',
      light: '#81c784',
      dark: '#43a047',
    },
    background: {
      default: '#f8f9fa',
      paper: '#ffffff',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#f57c00',
    },
    info: {
      main: '#0288d1',
    },
    success: {
      main: '#388e3c',
    },
    text: {
      primary: '#263238',
      secondary: '#546e7a',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h5: {
      fontWeight: 600,
      letterSpacing: 0.5,
    },
    subtitle1: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          padding: '8px 16px',
        },
        containedPrimary: {
          boxShadow: '0 2px 8px rgba(46, 125, 50, 0.2)',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 6,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
        },
      },
    },
  },
});

interface AdminData {
  username: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
}

interface FeedbackState {
  type: AlertColor | '';
  message: string;
}

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const PageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  '& .content': {
    flexGrow: 1,
    paddingTop: '80px',
    paddingBottom: theme.spacing(4),
  },
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '200px',
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  zIndex: 10,
  borderRadius: theme.shape.borderRadius,
  backdropFilter: 'blur(3px)',
}));

const GradientHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  padding: theme.spacing(3),
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
}));

export default function ManageAdmins() {
  const [showPassword, setShowPassword] = useState(false);
  const [isAddingAdmin, setIsAddingAdmin] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [errorModalMessage, setErrorModalMessage] = useState<string>('');
  const [emailVerificationModalOpen, setEmailVerificationModalOpen] = useState<boolean>(false);
  const [admin, setAdmin] = useState<AdminData>({
    username: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({
    open: false,
    message: '',
    severity: undefined,
  });

  const showSnackbar = (message: string, severity: AlertColor) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const validateForm = (): boolean => {
    if (!admin.username.trim()) {
      showSnackbar('Username is required', 'error');
      return false;
    }
    if (!admin.email.trim()) {
      showSnackbar('Email is required', 'error');
      return false;
    }
    if (!/^\S+@\S+\.\S+$/.test(admin.email)) {
      showSnackbar('Please enter a valid email address', 'error');
      return false;
    }
    if (admin.phone && !/^\d{10}$/.test(admin.phone)) {
      showSnackbar('Phone number must be 10 digits', 'error');
      return false;
    }
    if (!admin.password.trim() || admin.password.length < 6) {
      showSnackbar('Password must be at least 6 characters', 'error');
      return false;
    }
    if (admin.password !== admin.confirmPassword) {
      showSnackbar('Passwords do not match', 'error');
      return false;
    }
    return true;
  };

  const handleAdminChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAdmin({
      ...admin,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsAddingAdmin(true);

    try {
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');

      const payload = {
        ...admin,
        uid: userData.uid,
        email: admin.email
      };

      const response = await axios.post(
        `${environment.backendUrl}/api/admin/create`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200 || response.status === 201) {
        showSnackbar('Admin successfully added!', 'success');
        setAdmin({
          username: '',
          email: '',
          phone: '',
          password: '',
          confirmPassword: ''
        });
        setEmailVerificationModalOpen(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorData = error.response?.data;
        
        if (errorData?.error === "Admin with this email already exists" || 
            (errorData?.error === "Failed to create admin user" && 
             errorData?.details?.includes("EMAIL_EXISTS"))) {
          setErrorModalMessage("An account with this email address already exists. Please use a different email address.");
          setErrorModalOpen(true);
        } else {
          showSnackbar(
            error.response?.data?.error || 'Failed to add admin',
            'error'
          );
        }
      } else {
        showSnackbar('An unknown error occurred', 'error');
      }
    } finally {
      setIsAddingAdmin(false);
    }
  };

  return (
    <ThemeProvider theme={professionalTheme}>
      <PageWrapper>
        <Navbar currentPage="manage-admins" />

        <Box className="content">
          <Container maxWidth="md" sx={{ py: 4 }}>
            <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
              <GradientHeader>
                <Typography variant="h5" component="h1" color="white" fontWeight="600">
                  Manage Administrators
                </Typography>
                <Typography variant="subtitle2" color="white" sx={{ opacity: 0.9, mt: 0.5 }}>
                  Add and manage system administrators
                </Typography>
              </GradientHeader>

              <StyledCardContent>
                <LoadingContainer>
                  {isAddingAdmin && (
                    <LoadingOverlay>
                      <Box sx={{ textAlign: 'center' }}>
                        <CircularProgress size={40} color="primary" sx={{ mb: 2 }} />
                        <Typography variant="body2" color="textSecondary">
                          Adding administrator...
                        </Typography>
                      </Box>
                    </LoadingOverlay>
                  )}

                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'text.primary', fontWeight: 500 }}>
                          Admin Contact Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="username"
                          label="Username"
                          value={admin.username}
                          onChange={handleAdminChange}
                          fullWidth
                          required
                          variant="outlined"
                          placeholder="Enter username"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonIcon color="primary" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="email"
                          label="Email"
                          type="email"
                          value={admin.email}
                          onChange={handleAdminChange}
                          fullWidth
                          required
                          variant="outlined"
                          placeholder="Enter email address"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon color="primary" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="phone"
                          label="Phone Number"
                          value={admin.phone}
                          onChange={handleAdminChange}
                          fullWidth
                          variant="outlined"
                          placeholder="Enter phone number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <PhoneIcon color="primary" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom sx={{ color: 'text.primary', fontWeight: 500, mt: 2 }}>
                          Admin Password
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="password"
                          label="Password"
                          type={showPassword ? "text" : "password"}
                          value={admin.password}
                          onChange={handleAdminChange}
                          fullWidth
                          required
                          variant="outlined"
                          placeholder="Create password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <VpnKeyIcon color="primary" />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  edge="end"
                                  aria-label={showPassword ? "Hide password" : "Show password"}
                                >
                                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          name="confirmPassword"
                          label="Confirm Password"
                          type={showPassword ? "text" : "password"}
                          value={admin.confirmPassword}
                          onChange={handleAdminChange}
                          fullWidth
                          required
                          variant="outlined"
                          placeholder="Confirm password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <VpnKeyIcon color="primary" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="large"
                            startIcon={isAddingAdmin ? <CircularProgress size={20} color="inherit" /> : <AddIcon />}
                            disabled={isAddingAdmin}
                            sx={{ py: 1.5, fontSize: '1rem' }}
                          >
                            {isAddingAdmin ? 'Adding...' : 'Add Administrator'}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </LoadingContainer>
              </StyledCardContent>
            </Paper>
          </Container>
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            variant="filled"
            sx={{ width: '100%', boxShadow: 4 }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Footer />
        <CustomModal
          isModalVisible={errorModalOpen}
          onPress={() => setErrorModalOpen(false)}
          buttonText="OK"
          modalTitle="Admin Already Exist"
          text={errorModalMessage}
          icon={true}
        />
        <CustomModal
          isModalVisible={emailVerificationModalOpen}
          onPress={() => setEmailVerificationModalOpen(false)}
          buttonText="OK"
          modalTitle="Verify your Email ID"
          text="Please check your email to proceed. We've sent a verification link to your email."
          icon={true}
        />
      </PageWrapper>
    </ThemeProvider>
  );
}