import React, { useEffect, useState } from 'react';

import '../../scss/Form.scss';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import Sidebar from '../../components/Sidebar/Sidebar';
import { Link, useNavigate } from 'react-router-dom';
import mailicon from '../../../assets/img/signup-icon.svg';
import googleicon from '../../../assets/img/google-icon.svg';
import appleicon from '../../../assets/img/appleLogo.png';
import cleverIcon from '../../../assets/img/Clever-icon.png';
import classlinkIcon from '../../../assets/img/classlink-icon.png'

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  Autocomplete
} from '@mui/material';
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithCustomToken,
  getAuth,
  browserSessionPersistence,
  setPersistence
} from 'firebase/auth';
import { authen, provider, providerApple } from '../../firebase/firebase';
import validator from 'validator';
import { fetchCookieUsers } from '../../Utils/firebase-services';
import cookiePreferences from 'shared/Utils/CookiePreferenceApi/api';
import RemoteErrorLog from '../../Utils/api';
import { environment } from '../../../environments/environment';
import CustomModal from '../../components/CustomModal';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useParams } from 'react-router-dom';

interface School {
  name: string;
  address: string;
  city: string;
  school_id: string;
  state: string;
  displayName: string;
}

export default function LogIn() {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorStatus, setErrorStatus] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [rememberMe, setRememberMe] = useState(false);
  const [enable, setEnable] = useState<boolean>(false);
  const [enable1, setEnable1] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isQuestionModalVisible, setQuestionModalVisible] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const [firebaseUser, setFirebaseUser] = useState<any>(null);
  const [firebaseUserProvider, setFirebaseUserProvider] = useState<string>('');
  const [isCookieModalVisible, setIsCookieModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loginType, setLoginType] = useState<'school' | 'email'>('school');
  const [schoolName, setSchoolName] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [schools, setSchools] = useState<School[]>([]);
  const [schoolError, setSchoolError] = useState<string | null>(null);
  const [isLoadingSchools, setIsLoadingSchools] = useState(false);

  const { schoolId } = useParams<{ schoolId?: string }>();

  // const [isCookiePolicyModalVisible, setIsCookiePolicyModalVisible] =
  //   useState(false);

  useEffect(() => {
    const fetchSchoolsData = async () => {
      if (schoolId && schools.length === 0) {
        try {
          setIsLoadingSchools(true);
          setSchoolError(null);
    
          const response = await axios.get(`${environment.backendUrl}api/schools`);
    
          if (response.data.status === 'success') {
            setSchools(response.data.schools);
            
            const school = response.data.schools.find(
              (s: School) => s.school_id === schoolId
            );
            
            if (school) {
              setLoginType('school');
              setSchoolName(school.name);
            } else {
              setSchoolError('Invalid school ID');
            }
          } else {
            throw new Error('Failed to fetch schools');
          }
        } catch (error) {
          RemoteErrorLog(error, 'Login.tsx');
          console.error('Error fetching schools:', error);
          setSchoolError('Unable to load schools. Please try again later.');
        } finally {
          setIsLoadingSchools(false);
        }
      }
    };

    fetchSchoolsData();
  }, [schoolId]);

  useEffect(() => {
    if (schoolId && schools.length > 0) {
      const selectedSchool = schools.find(
        (school) => school.school_id === schoolId
      );
      if (selectedSchool) {
        setLoginType('school');
        setSchoolName(selectedSchool.name);
      }
    }
  }, [schoolId, schools]);

  const loadCredentials = async () => {
    if (schoolId) return;

    const storedCredentials = await localStorage.getItem('userCredentials');
    if (storedCredentials) {
      const credentials = JSON.parse(storedCredentials);
      setRememberMe(true);
      setEnable(true);
      setEnable1(true);
  
      if (credentials.loginType === 'school') {
        setLoginType('school');
        setSchoolName(credentials.schoolName || '');
        setUsername(credentials.username || '');
      } else {
        setLoginType('email');
        setEmail(credentials.email);
      }
      setPassword(credentials.password);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const signInAnonymouslyy = async () => {
    setIsLoading(true);
    signInAnonymously(authen)
      .then(async (userCredential) => {
        const { uid, isAnonymous, displayName } = userCredential.user;

        fetchCookieUsers(uid).then((abc) => {
          setFirebaseUser({ uid, isAnonymous, displayName });
          localStorage.setItem('userData', JSON.stringify(userCredential.user));
        });
      })
      .catch((error) => {
        RemoteErrorLog(error, 'Login.tsx');
      });
  };

  const handleSchoolFieldClick = async () => {
    if (schools.length === 0 && !isLoadingSchools) {
      try {
        setIsLoadingSchools(true);
        setSchoolError(null);
  
        const response = await axios.get(`${environment.backendUrl}api/schools`);
  
        if (response.data.status === 'success') {
          setSchools(response.data.schools);
          
          if (schoolId) {
            const school = response.data.schools.find(
              (s: School) => s.school_id === schoolId
            );
            if (school) {
              setSchoolName(school.name);
            }
          }
        } else {
          throw new Error('Failed to fetch schools');
        }
      } catch (error) {
        RemoteErrorLog(error, 'Login.tsx');
        console.error('Error fetching schools:', error);
        setSchoolError('Unable to load schools. Please try again later.');
      } finally {
        setIsLoadingSchools(false);
      }
    }
  };

  const userLogin = async () => {
    setIsLoading(true);
    try {
      let loginEmail;
  
      if (loginType === 'school') {
        try {
          const verificationResponse = await axios.post(
            `${environment.backendUrl}api/verify-school-user`,
            {
              schoolName,
              username
            }
          );
          
          if (verificationResponse.data.status === 'success') {
            loginEmail = verificationResponse.data.email;
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            const errorMessage = error.response?.data?.error || 'Invalid school or username';
            window.alert(errorMessage);
            setIsLoading(false);
            return;
          }
        }
      } else {
        loginEmail = email;
      }
  
      const storedCredentials = await localStorage.getItem('userCredentials');
      if (storedCredentials && !rememberMe) {
        await localStorage.removeItem('userCredentials');
      }
  
      const userCredential = await signInWithEmailAndPassword(
        authen,
        loginEmail,
        password
      );
      const user = userCredential.user;
      
      if (rememberMe) {
        await localStorage.setItem(
          'userCredentials',
          JSON.stringify({ 
            email: loginEmail, 
            password,
            loginType,
            ...(loginType === 'school' ? { 
              schoolName,
              username 
            } : {})
          })
        );
      }
      if (user.emailVerified) {
        await user.getIdToken(true);
        const idTokenResult = await user.getIdTokenResult();
        
        localStorage.setItem('userData', JSON.stringify(userCredential.user));
        const userCookiePreference = await fetchCookieUsers(user.uid);
        const { uid, isAnonymous, displayName } = user;
        if (userCookiePreference === 'Error') {
          setQuestionModalVisible(true);
          setApiErrorMessage(
            'We’re currently experiencing some issues. Please try again later.'
          );
          setIsLoading(false);
          localStorage.removeItem('userData');
        } else {
          setIsLoading(false);
          
          if (idTokenResult.claims.indian_school_admin) {
            navigate('/student-signup');
          } else {
            navigate('/home');
          }
        }
      } else {
        setIsModalVisible(true);

        setIsLoading(false);
      }
    } catch (error: any) {
      setIsLoading(false);

      RemoteErrorLog(error, 'Login.tsx');
      window.alert(
        'The email or password entered is incorrect. Please try again.'
      );
    }
  };

  const validateEmail = (input: string) => {
    setEmail(input);
    if (!input) {
      setErrorStatus('Email is required.');
      setEnable(false);
      return;
    }
    if (validator.isEmail(input)) {
      setEnable(true);
      setErrorStatus('');
    } else {
      setEnable(false);
      setErrorStatus('Please enter a valid email address.');
    }
  };

  const validatePassword = (text: string) => {
    if (text.length < 6) {
      setErrorMessage('The password must be at least 6 characters long.');
      setEnable1(false);
    } else {
      setEnable1(true);
      setErrorMessage('');
    }
  };
  const googleSignIn = async () => {
    try {
      const result = await signInWithPopup(authen, provider);
      const googleCredentials = GoogleAuthProvider.credentialFromResult(result);

      const userInfo = signInWithCredential(authen, googleCredentials!);

      setIsLoading(true);
      localStorage.setItem('userData', JSON.stringify((await userInfo).user));
      const { uid, isAnonymous, displayName } = (await userInfo).user;
      const userCookiePrefernce = await fetchCookieUsers(uid);
      if (userCookiePrefernce == 'Error') {
        setQuestionModalVisible(true);
        setApiErrorMessage(
          'We’re currently experiencing some issues. Please try again later.'
        );
        setIsLoading(false);
        localStorage.removeItem('userData');
      } else if (userCookiePrefernce == false) {
        setFirebaseUser({ uid, isAnonymous, displayName });
      } else {
        setIsLoading(false);
        navigate('/home');
      }
      setFirebaseUserProvider((await userInfo).user.providerData[0].providerId);
    } catch (error: any) {
      RemoteErrorLog(error, 'Login.tsx');
    }
  };
  const appleSignIn = async () => {
    try {
      providerApple.addScope('email');
      providerApple.addScope('name');
      const result = await signInWithPopup(authen, providerApple);
      // const googleCredentials =
      //   GoogleAuthProvider.credentialFromResult(result);
      const credential = OAuthProvider.credentialFromResult(result);
      const userInfo = signInWithCredential(authen, credential!);

      setIsLoading(true);
      localStorage.setItem('userData', JSON.stringify((await userInfo).user));
      const { uid, isAnonymous, displayName } = (await userInfo).user;
      const userCookiePrefernce = await fetchCookieUsers(uid);
      if (userCookiePrefernce !== true) {
        setFirebaseUser({ uid, isAnonymous, displayName } as any);
      } else {
        setIsLoading(false);
        navigate('/home');
      }
      setFirebaseUserProvider((await userInfo).user.providerData[0].providerId);
    } catch (error: any) {
      RemoteErrorLog(error, 'Login.tsx');
    }
  };

  const cleverSignIn = async () => {
    try {
      const auth = getAuth();
      await setPersistence(auth, browserSessionPersistence);
      const width = 500;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;
  
      const popup = window.open(
        'about:blank',
        'Clever Login',
        `width=${width},height=${height},left=${left},top=${top}`
      );
  
      if (!popup) {
        throw new Error('Popup blocked. Please enable popups and try again.');
      }
  
      const response = await axios.get(`${environment.backendUrl}oauth/authorize`);
      popup.location.replace(response.data.login_url);
      const userInfo = await new Promise<{
        user: any,
      }>((resolve, reject) => {
        const popupCheckInterval = setInterval(() => {
          if (popup.closed) {
            clearInterval(popupCheckInterval);
            window.removeEventListener('message', handleMessage);
            reject(new Error('Login cancelled'));
          }
        }, 500);
  
        const handleMessage = async (event: MessageEvent) => {
          if (event.data.error) {
            clearInterval(popupCheckInterval);
            reject(new Error(event.data.message || 'Error during Clever login'));
            return;
          }
  
          if (event.data.token && event.data.user_id) {
            try {
              clearInterval(popupCheckInterval);
              const credential = await signInWithCustomToken(auth, event.data.token);
              resolve({ 
                user: credential.user, 
                // first_login: event.data.first_login, 
                // spelling_preference_set: event.data.spelling_preference_set 
              });
            } catch (error) {
              reject(error);
            } finally {
              popup.close();
              window.removeEventListener('message', handleMessage);
            }
          }
        };
  
        window.addEventListener('message', handleMessage);
        setTimeout(() => {
          clearInterval(popupCheckInterval);
          window.removeEventListener('message', handleMessage);
          popup.close();
          reject(new Error('Login timed out. Please try again.'));
        }, 60000);
      });
  
      localStorage.setItem('userData', JSON.stringify(userInfo.user));
      const { uid, isAnonymous, displayName } = userInfo.user;
  
      const userCookiePreference = await fetchCookieUsers(uid);
      if (userCookiePreference === 'Error') {
        setQuestionModalVisible(true);
        setApiErrorMessage(
          "We're currently experiencing some issues. Please try again later."
        );
        localStorage.removeItem('userData');
      } else if (userCookiePreference === false) {
        setFirebaseUser({ uid, isAnonymous, displayName });
      } 
      /* else if (userInfo.first_login || !userInfo.spelling_preference_set) {
        navigate('/home', { state: { showSpellingPrefModal: true } });
      } */ 
      else {
        setIsLoading(false);
        navigate('/home');
      }
  
      setFirebaseUserProvider('clever.com');
  
    } catch (error: any) {
      if (error.message === 'Login cancelled') {
        return;
      }
      RemoteErrorLog(error, 'Login.tsx');
      if (error.message.includes("We can't find your account")) {
        setQuestionModalVisible(true);
        setApiErrorMessage(error.message);
      } else if (error.message === 'Login timed out. Please try again.') {
        window.alert(error.message);
      } else {
        window.alert('Failed to authenticate with Clever. Please try again.');
      }
    }
  };

  const classlinkSignIn = async () => {
    try {
      const auth = getAuth();
      await setPersistence(auth, browserSessionPersistence);
      const width = 500;
      const height = 600;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;
  
      const popup = window.open(
        'about:blank',
        'Classlink Login',
        `width=${width},height=${height},left=${left},top=${top}`
      );
  
      if (!popup) {
        throw new Error('Popup blocked. Please enable popups and try again.');
      }
      const response = await axios.get(`${environment.backendUrl}classlink/authorize`);
      popup.location.replace(response.data.login_url);
  
      const userInfo = await new Promise<{
        user: any
      }>((resolve, reject) => {
        const popupCheckInterval = setInterval(() => {
          if (popup.closed) {
            clearInterval(popupCheckInterval);
            window.removeEventListener('message', handleMessage);
            reject(new Error('Login cancelled'));
          }
        }, 500);
        const handleMessage = async (event: MessageEvent) => {
          if (event.data.error) {
            clearInterval(popupCheckInterval);
            reject(new Error(event.data.message || 'Error during Classlink login'));
            return;
          }
  
          if (event.data.token && event.data.user_id) {
            try {
              clearInterval(popupCheckInterval);
              const credential = await signInWithCustomToken(auth, event.data.token);
              resolve({ 
                user: credential.user 
              });
            } catch (error) {
              reject(error);
            } finally {
              popup.close();
              window.removeEventListener('message', handleMessage);
            }
          }
        };
  
        window.addEventListener('message', handleMessage);
        setTimeout(() => {
          clearInterval(popupCheckInterval);
          window.removeEventListener('message', handleMessage);
          popup.close();
          reject(new Error('Login timed out. Please try again.'));
        }, 60000);
      });
  
      localStorage.setItem('userData', JSON.stringify(userInfo.user));
      const { uid, isAnonymous, displayName } = userInfo.user;
  
      const userCookiePreference = await fetchCookieUsers(uid);
      if (userCookiePreference === 'Error') {
        setQuestionModalVisible(true);
        setApiErrorMessage(
          "We're currently experiencing some issues. Please try again later."
        );
        localStorage.removeItem('userData');
      } else if (userCookiePreference === false) {
        setFirebaseUser({ uid, isAnonymous, displayName });
      } else {
        navigate('/home');
      }
  
      setFirebaseUserProvider('classlink.com');
  
    } catch (error: any) {
      if (error.message === 'Login cancelled') {
        return;
      }
      RemoteErrorLog(error, 'Login.tsx');
      if (error.message.includes("We can't find your account")) {
        setQuestionModalVisible(true);
        setApiErrorMessage(error.message);
      } else if (error.message === 'Login timed out. Please try again.') {
        window.alert(error.message);
      } else {
        window.alert('Failed to authenticate with Classlink. Please try again.');
      }
    }
  };

  const validateInputs = () => {
    if (loginType === 'school') {
      return schoolName && username && password.length >= 6;
    } else {
      return enable && enable1;
    }
  };

  useEffect(() => {
    loadCredentials();
  }, []);

  useEffect(() => {
    if (firebaseUser) {
      // cookieModalOpen();
      hideCookieModal();
    }
  }, [firebaseUser]);

  const cookieModalOpen = () => {
    setIsLoading(false);
    setIsCookieModalVisible(!isCookieModalVisible);
  };
  const hideModal = () => {
    setIsModalVisible(false);
    setQuestionModalVisible(false);
  };

  const cookieApiCall = async (cookieInfo: any) => {
    const token = await (authen?.currentUser as any).getIdToken(true);
    const result = await cookiePreferences(
      cookieInfo,
      token,
      environment?.apiUrl
    );
    if (result == 'Error') {
      setQuestionModalVisible(true);
      setApiErrorMessage(
        'We’re currently experiencing some issues. Please try again later.'
      );
      setIsLoading(false);
      localStorage.removeItem('userData');
    } else {
      setIsLoading(false);
      navigate('/home');
    }
  };

  const hideCookieModal = async () => {
    setIsCookieModalVisible(false);
    const cookieInfo = {
      uid: firebaseUser.uid,
      full_name: firebaseUser.displayName,
      is_anonymous: firebaseUser.isAnonymous,
      dob: null,
    };
    if (cookieInfo.uid) {
      setIsLoading(true);
      cookieApiCall(cookieInfo);
    }
  };
  // const openCookiePolicyModal = () => {
  //   setIsCookieModalVisible(false);
  //   setIsCookiePolicyModalVisible(true);
  // };

  // const onSavePreference = () => {
  //   setIsCookiePolicyModalVisible(false);

  //   navigate('/home');
  // };
  return (
    <>
      <Helmet>
        <title>
          Login to Our Free Online Spelling Test Game - Spelling Hero
        </title>
        <meta
          name="description"
          content="Login to Spelling Hero app, an online spelling test app to practice spelling words with fun activities by playing games to improve your spelling skills. Login now!"
        />
        <link rel="canonical" href="https://spellinghero.app/login"></link>
      </Helmet>
      <header>
        <Navbar currentPage="login" />
      </header>
      <main className="main-container">
        <section className="quiz-signup">
          {/* <CookiesModal
            firebaseUserProvider={firebaseUserProvider}
            firebaseUser={firebaseUser}
            // navigation={navigation}
            isModalVisible={isCookieModalVisible}
            onAccept={hideCookieModal}
            onManage={openCookiePolicyModal}
          />

          <CookiePolicyModal
            firebaseUserProvider={firebaseUserProvider}
            firebaseUser={firebaseUser}
            // navigation={navigation}
            isModalVisible={isCookiePolicyModalVisible}
            onAccept={hideCookieModal}
            onSavePreference={onSavePreference}
            handleClose={() => setIsCookiePolicyModalVisible(false)}
          /> */}
          <Box sx={{ padding: 0 }}>
            <Grid container spacing={2}>
              <Grid
                item
                lg={5}
                xs={12}
                sm={12}
                sx={{ paddingLeft: 0 }}
                className="sidebar-top-spacing"
              >
                <Sidebar />
              </Grid>
              <Grid item xs={12} sm={12} lg={7} xl={6}>
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100vh',
                    }}
                  >
                    {' '}
                    <CircularProgress
                      size={56}
                      sx={{
                        color: 'primary.main',
                      }}
                    />{' '}
                  </Box>
                ) : (
                  <Box
                    className="form-container"
                    sx={{ paddingLeft: { lg: 3, xl: 5 } }}
                  >
                    <Box>
                      <Typography variant="h4" className="mb-4 font-600">
                        Log in
                      </Typography>
                      <form>
                        <FormControl component="fieldset" className="mb-4">
                          <RadioGroup
                            row
                            value={loginType}
                            onChange={(e) => setLoginType(e.target.value as 'school' | 'email')}
                          >
                            <FormControlLabel
                              value="school"
                              control={
                                <Radio
                                  sx={{
                                    '&.Mui-checked': {
                                      color: 'green',
                                    },
                                    '& .MuiTouchRipple-root': {
                                      color: 'green',
                                    }
                                  }}
                                />
                              }
                              label="India School Plan"
                            />
                            <FormControlLabel
                              value="email"
                              control={
                                <Radio
                                  sx={{
                                    '&.Mui-checked': {
                                      color: 'green',
                                    },
                                    '& .MuiTouchRipple-root': {
                                      color: 'green',
                                    }
                                  }}
                                />
                              }
                              label="Email"
                            />
                          </RadioGroup>
                        </FormControl>

                        {loginType === 'school' ? (
                          <>
                            <Grid container spacing={2} alignItems="center" className="mb-3 signup-flex-basis-remove">
                              <Grid item xs={12} md={4} sx={{ flexBasis: '33%' }}>
                                <Typography variant="body1" component="label" className="form-label mb-md-0">
                                  Select your school*
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={8} sx={{ flexBasis: '67%' }}>
                                <Autocomplete<School>
                                  options={schools}
                                  loading={isLoadingSchools}
                                  getOptionLabel={(option: School) => 
                                    `${option.name}, ${option.address}, ${option.city}, ${option.state}`
                                  }
                                  value={schools.find((school: School) => school.name === schoolName) || null}
                                  onChange={(event, newValue: School | null) => {
                                    setSchoolName(newValue ? newValue.name : '');
                                  }}
                                  onOpen={handleSchoolFieldClick}
                                  isOptionEqualToValue={(option, value) => option.name === value.name}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      placeholder={isLoadingSchools ? "Loading schools..." : "Search for your school"}
                                      variant="outlined"
                                      error={!!schoolError}
                                      helperText={schoolError}
                                      InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                          <>
                                            {isLoadingSchools ? (
                                              <CircularProgress color="inherit" size={20} />
                                            ) : null}
                                            {params.InputProps.endAdornment}
                                          </>
                                        ),
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>

                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              className="mb-3 signup-flex-basis-remove"
                            >
                              <Grid item xs={12} md={4} sx={{ flexBasis: '33%' }}>
                                <Typography
                                  variant="body1"
                                  component="label"
                                  className="form-label mb-md-0"
                                >
                                  Username
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={8} sx={{ flexBasis: '67%' }}>
                                <TextField
                                  fullWidth
                                  value={username}
                                  placeholder="Enter your username"
                                  variant="outlined"
                                  onChange={(e) => setUsername(e.target.value)}
                                />
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <Grid container spacing={2} alignItems="center" className="mb-3 signup-flex-basis-remove">
                            <Grid item xs={12} md={4} sx={{ flexBasis: '33%' }}>
                              <Typography variant="body1" component="label" className="form-label mb-md-0">
                                Your email*
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={8} sx={{ flexBasis: '67%' }}>
                              <TextField
                                fullWidth
                                value={email}
                                placeholder="example@email.com"
                                variant="outlined"
                                onChange={(e) => validateEmail(e.target.value)}
                              />
                              {errorStatus && (
                                <p style={{ color: 'red' }}>{errorStatus}</p>
                              )}
                            </Grid>
                          </Grid>
                        )}

                        <Grid container spacing={2} alignItems="center" className="mb-2 signup-flex-basis-remove">
                          <Grid item xs={12} md={4} sx={{ flexBasis: '33%' }}>
                            <Typography
                              variant="body1"
                              component="label"
                              className="form-label mb-md-0"
                            >
                              Password*
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={8} sx={{ flexBasis: '67%' }}>
                            <TextField
                              type="password"
                              value={password}
                              onChange={(e) => {
                                setPassword(e.target.value);
                                validatePassword(e.target.value);
                              }}
                              fullWidth
                              placeholder="Please enter at least 6 characters."
                              variant="outlined"
                            />
                            {errorMessage ? (
                              <p style={{ color: 'red' }}>{errorMessage}</p>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          alignItems="center"
                          className="mb-4"
                        >
                          <Grid item xs={12} md={8} sx={{ ml: { md: 'auto' } }}>
                            <Box
                              className="d-flex justify-content-between mb-3 signup-flex-basis-remove font-14"
                              sx={{ alignItems: 'center' }}
                            >
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={rememberMe}
                                    onChange={(e) =>
                                      setRememberMe(e.target.checked)
                                    }
                                  />
                                }
                                label="Remember Me"
                              />
                              <Link to="/forgot-password">
                                Forgot Password?
                              </Link>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                          <Grid item xs={12} md={10} sx={{ margin: 'auto' }}>
                            <Button
                              variant="contained"
                              sx={{ textTransform: 'none' }}
                              className="btn btn-primary"
                              fullWidth
                              disabled={!validateInputs()}
                              onClick={() => userLogin()}
                            >
                              Log in
                            </Button>
                            <Box className="seperator">
                              <span>OR</span>
                            </Box>
                            <Button
                              variant="outlined"
                              sx={{ textTransform: 'none' }}
                              className="btn btn-member btn-outline-secondary "
                              onClick={googleSignIn}
                              fullWidth
                              startIcon={
                                <img
                                  src={googleicon}
                                  alt="google icon"
                                  className="icon-offset"
                                />
                              }
                            >
                              Login with Google
                            </Button>
                            <Button
                              variant="outlined"
                              className="btn btn-member btn-outline-secondary "
                              sx={{
                                textTransform: 'none',
                                marginTop: '20px',
                              }}
                              onClick={appleSignIn}
                              fullWidth
                              startIcon={
                                <img
                                  src={appleicon}
                                  alt="google icon"
                                  className="icon-offset"
                                />
                              }
                            >
                              Login with Apple
                            </Button>
                            <Button
                              variant="outlined"
                              className="btn btn-member btn-outline-secondary "
                              sx={{
                                textTransform: 'none',
                                marginTop: '20px',
                              }}
                              onClick={cleverSignIn}
                              fullWidth
                              startIcon={
                                <img
                                  src={cleverIcon}
                                  alt="clever icon"
                                  className="icon-offset"
                                />
                              }
                            >
                              Login with Clever
                            </Button>
                            <Button
                              variant="outlined"
                              className="btn btn-member btn-outline-secondary "
                              sx={{
                                textTransform: 'none',
                                marginTop: '20px',
                              }}
                              onClick={classlinkSignIn}
                              fullWidth
                              startIcon={
                                <img
                                  src={classlinkIcon}
                                  alt="clever icon"
                                  className="icon-offset"
                                />
                              }
                            >
                              Login with ClassLink
                            </Button>
                            <Link
                              to="/signup"
                              style={{ textDecoration: 'none' }}
                            >
                              <Button
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                                fullWidth
                                startIcon={
                                  <img
                                    src={mailicon}
                                    alt="Mail Icon"
                                    className="icon-offset"
                                  />
                                }
                                className="mt-3 btn btn-primary"
                              >
                                New user? Sign up for free
                              </Button>
                            </Link>

                            {/* <Typography
                              variant="body2"
                              align="center"
                              sx={{ textTransform: 'none' }}
                              className="mt-3 font-600 cursor-pointer"
                            >
                              Explore as{' '}
                              <Link to="">
                                <span onClick={signInAnonymouslyy}>Guest</span>
                              </Link>
                            </Typography> */}
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </section>

        <CustomModal
          isModalVisible={isModalVisible}
          onPress={hideModal}
          buttonText="OK"
          modalTitle="Verify your Email ID"
          text="Before login, please verify your email address. Click the verification link we’ve sent to your inbox!"
          icon={true}
        />
        <CustomNoResponseModal
          isModalVisible={isQuestionModalVisible}
          onPress={hideModal}
          buttonText="OK"
          modalTitle="Oops!"
          text={apiErrorMessage}
        />
      </main>
      <Footer />
    </>
  );
}
