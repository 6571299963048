import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { authen } from '../firebase/firebase';

const RequireAuthGuest = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const userData = localStorage.getItem('userData');
  
  useEffect(() => {
    const unsubscribe = authen.onAuthStateChanged((user) => {
      if (!user && userData) {
        localStorage.removeItem('userData');
      }
    });

    return () => unsubscribe();
  }, [userData]);

  if (!userData) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  try {
    const data = JSON.parse(userData);
    if (!data?.uid) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  } catch (error) {
    localStorage.removeItem('userData');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export default RequireAuthGuest;
