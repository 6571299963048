import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import {
  TextField,
  Button,
  CardContent,
  Typography,
  Alert,
  CircularProgress,
  Tabs,
  Tab,
  Box,
  AlertColor,
  Paper,
  Container,
  Snackbar,
  IconButton,
  Tooltip,
  Grid,
  Divider,
  InputAdornment,
  createTheme,
  ThemeProvider
} from '@mui/material';
import {
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  GetApp as GetAppIcon,
  Refresh as RefreshIcon,
  Person as PersonIcon,
  VpnKey as VpnKeyIcon,
  Email as EmailIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { styled } from '@mui/material/styles';
import { environment } from '../../../environments/environment';
import axios from 'axios';
import CustomModal from '../../components/CustomModal';

const professionalTheme = createTheme({
  palette: {
    primary: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#66bb6a',
      light: '#81c784',
      dark: '#43a047',
    },
    background: {
      default: '#f8f9fa',
      paper: '#ffffff',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#f57c00',
    },
    info: {
      main: '#0288d1',
    },
    success: {
      main: '#388e3c',
    },
    text: {
      primary: '#263238',
      secondary: '#546e7a',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h5: {
      fontWeight: 600,
      letterSpacing: 0.5,
    },
    subtitle1: {
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 8,
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(0,0,0,0.05),0px 1px 1px 0px rgba(0,0,0,0.03),0px 1px 3px 0px rgba(0,0,0,0.05)',
    '0px 3px 1px -2px rgba(0,0,0,0.05),0px 2px 2px 0px rgba(0,0,0,0.05),0px 1px 5px 0px rgba(0,0,0,0.05)',
    '0px 3px 3px -2px rgba(0,0,0,0.06),0px 3px 4px 0px rgba(0,0,0,0.04),0px 1px 8px 0px rgba(0,0,0,0.06)',
  ] as any,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          padding: '8px 16px',
        },
        containedPrimary: {
          boxShadow: '0 2px 8px rgba(46, 125, 50, 0.2)',
        },
        outlinedPrimary: {
          borderWidth: 1.5,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 6,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 56,
          fontWeight: 500,
        },
      },
    },
  },
});

interface SingleStudentData {
  studentId: string;
  email: string;
  password: string;
}

interface FeedbackState {
  type: AlertColor | '';
  message: string;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const PageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  backgroundColor: theme.palette.background.default,
  '& .content': {
    flexGrow: 1,
    paddingTop: '80px',
    paddingBottom: theme.spacing(4),
  },
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '200px',
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  zIndex: 10,
  borderRadius: theme.shape.borderRadius,
  backdropFilter: 'blur(3px)',
}));

const FileUploadArea = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  backgroundColor: 'rgba(46, 125, 50, 0.05)',
  borderStyle: 'dashed',
  borderWidth: '2px',
  borderColor: theme.palette.primary.light,
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.08)',
  },
}));

const GradientHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
  padding: theme.spacing(3),
  borderTopLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,
}));

const TabWrapper = styled(Tabs)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '& .MuiTab-root': {
    transition: 'all 0.2s',
    '&:hover': {
      backgroundColor: 'rgba(46, 125, 50, 0.04)',
    },
    '&.Mui-selected': {
      fontWeight: 600,
    },
  },
  '& .MuiTabs-indicator': {
    height: 3,
  },
}));

export default function StudentSignup() {
  const [activeTab, setActiveTab] = useState(0);
  const [bulkUploadFile, setBulkUploadFile] = useState<File | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isAddingStudent, setIsAddingStudent] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [errorModalMessage, setErrorModalMessage] = useState<string>('');

  const [feedback, setFeedback] = useState<FeedbackState>({
    type: '',
    message: '',
  });
  const [singleStudent, setSingleStudent] = useState<SingleStudentData>({
    studentId: '',
    email: '',
    password: '',
  });
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor | undefined;
  }>({
    open: false,
    message: '',
    severity: undefined,
  });

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    setFeedback({ type: '', message: '' });
  };

  const handleSingleStudentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSingleStudent({
      ...singleStudent,
      [e.target.name]: e.target.value,
    });
  };

  const showSnackbar = (message: string, severity: AlertColor) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const validateSingleStudentForm = (): boolean => {
    if (!singleStudent.studentId.trim()) {
      setErrorModalMessage('Student ID is required');
      setErrorModalOpen(true);
      return false;
    }
    if (singleStudent.email && !/^\S+@\S+\.\S+$/.test(singleStudent.email)) {
      setErrorModalMessage('If provided, email must be a valid email address');
      setErrorModalOpen(true);
      return false;
    }
    if (!singleStudent.password.trim() || singleStudent.password.length < 6) {
      setErrorModalMessage('Password must be at least 6 characters');
      setErrorModalOpen(true);
      return false;
    }
    return true;
  };

  const handleSingleStudentSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateSingleStudentForm()) {
      return;
    }

    setIsAddingStudent(true);

    try {
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');

      const response = await axios.post(
        `${environment.backendUrl}/api/student/signup`,
        {
          ...singleStudent,
          uid: userData.uid
        },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      if (response.status === 200 || response.status === 201) {
        showSnackbar('Student successfully added!', 'success');
        setSingleStudent({ studentId: '', email: '', password: '' });
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorMessage = error.response?.data?.error || 'Failed to add student';
        setErrorModalMessage(errorMessage);
        setErrorModalOpen(true);
      } else {
        setErrorModalMessage('An unknown error occurred');
        setErrorModalOpen(true);
      }
    } finally {
      setIsAddingStudent(false);
    }
  };

  const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.type === 'text/csv') {
      setBulkUploadFile(file);
      setFeedback({ type: '', message: '' });
      showSnackbar(`File "${file.name}" selected`, 'info');
    } else {
      showSnackbar('Please select a valid CSV file', 'error');
      setBulkUploadFile(null);
    }
  };

  const handleBulkUpload = async () => {
    if (!bulkUploadFile) {
      showSnackbar('Please select a file first', 'error');
      return;
    }

    const formData = new FormData();
    formData.append('file', bulkUploadFile);

    const userData = JSON.parse(localStorage.getItem('userData') || '{}');

    if (!userData.uid) {
      showSnackbar('User authentication error. Please log in again.', 'error');
      return;
    }

    formData.append('uid', userData.uid);

    setIsUploading(true);
    try {
      const response = await fetch(`${environment.backendUrl}/api/student/bulk-signup`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (response.ok) {
        showSnackbar('Bulk upload processed successfully', 'success');
        setBulkUploadFile(null);
        if (data.failed_count > 0) {
          setFeedback({
            type: 'warning',
            message: `Processed with some errors: ${data.successful_count} successful, ${data.failed_count} failed.`
          });
        } else {
          setFeedback({
            type: 'success',
            message: `Successfully processed ${data.successful_count} students.`
          });
        }
      } else {
        throw new Error(data.error || 'Failed to process bulk upload');
      }
    } catch (error) {
      showSnackbar(
        error instanceof Error ? error.message : 'An unknown error occurred',
        'error'
      );
    } finally {
      setIsUploading(false);
    }
  };

  const handleDownloadTemplate = () => {
    const csvContent = "student_id\n" +
      "student001\n" +
      "student002";

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', 'student-template.csv');
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <ThemeProvider theme={professionalTheme}>
      <PageWrapper>
        <Navbar currentPage="student-signup" />

        <Box className="content">
          <Container maxWidth="md" sx={{ py: 4 }}>
            <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
              <GradientHeader>
                <Typography variant="h5" component="h1" color="white" fontWeight="600">
                  Manage Students
                </Typography>
                <Typography variant="subtitle2" color="white" sx={{ opacity: 0.9, mt: 0.5 }}>
                  Manage student accounts in your system
                </Typography>
              </GradientHeader>

              <TabWrapper
                value={activeTab}
                onChange={handleTabChange}
                variant="fullWidth"
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <Tab
                  label="Single Student"
                  icon={<PersonIcon />}
                  iconPosition="start"
                />
                <Tab
                  label="Bulk Upload"
                  icon={<CloudUploadIcon />}
                  iconPosition="start"
                />
              </TabWrapper>

              <StyledCardContent>
                {activeTab === 0 && (
                  <LoadingContainer>
                    {isAddingStudent && (
                      <LoadingOverlay>
                        <Box sx={{ textAlign: 'center' }}>
                          <CircularProgress size={40} color="primary" sx={{ mb: 2 }} />
                          <Typography variant="body2" color="textSecondary">
                            Adding student...
                          </Typography>
                        </Box>
                      </LoadingOverlay>
                    )}

                    <form onSubmit={handleSingleStudentSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            name="studentId"
                            label="Student ID"
                            value={singleStudent.studentId}
                            onChange={handleSingleStudentChange}
                            fullWidth
                            required
                            variant="outlined"
                            placeholder="Enter student ID"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PersonIcon color="primary" />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            value={singleStudent.password}
                            onChange={handleSingleStudentChange}
                            fullWidth
                            required
                            variant="outlined"
                            placeholder="Create a secure password"
                            helperText="Password must be at least 6 characters"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <VpnKeyIcon color="primary" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    edge="end"
                                    aria-label={showPassword ? "Hide password" : "Show password"}
                                  >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              fullWidth
                              size="large"
                              startIcon={isAddingStudent ? <CircularProgress size={20} color="inherit" /> : <AddIcon />}
                              disabled={isAddingStudent}
                              sx={{ py: 1.5, fontSize: '1rem' }}
                            >
                              {isAddingStudent ? 'Adding...' : 'Add Student'}
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  </LoadingContainer>
                )}

                {activeTab === 1 && (
                  <LoadingContainer>
                    {isUploading && (
                      <LoadingOverlay>
                        <Box sx={{ textAlign: 'center' }}>
                          <CircularProgress size={40} color="primary" sx={{ mb: 2 }} />
                          <Typography variant="body2" color="textSecondary">
                            Uploading and processing students...
                          </Typography>
                        </Box>
                      </LoadingOverlay>
                    )}

                    <FileUploadArea variant="outlined">
                      <Typography variant="subtitle1" gutterBottom fontWeight={500}>
                        Bulk Student Upload
                      </Typography>
                      <Typography variant="body2" color="textSecondary" paragraph>
                        Upload a CSV file containing multiple student information at once.
                        Each row should include a student ID.
                      </Typography>

                      {feedback.message && (
                        <Alert
                          severity={feedback.type as 'success' | 'warning' | 'error' | 'info'}
                          sx={{ mb: 2 }}
                          variant="outlined"
                        >
                          {feedback.message}
                        </Alert>
                      )}

                      <Box sx={{ textAlign: 'center', my: 3 }}>
                        <Button
                          component="label"
                          variant={bulkUploadFile ? "contained" : "outlined"}
                          color="primary"
                          startIcon={<CloudUploadIcon />}
                          sx={{ mb: 2, px: 3, py: 1.2 }}
                          disabled={isUploading}
                        >
                          {bulkUploadFile ? 'Change File' : 'Select CSV File'}
                          <VisuallyHiddenInput
                            type="file"
                            accept=".csv"
                            onChange={handleFileSelect}
                            disabled={isUploading}
                          />
                        </Button>

                        {bulkUploadFile && (
                          <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 1.5,
                            bgcolor: 'rgba(46, 125, 50, 0.08)',
                            borderRadius: 1.5,
                            border: '1px solid rgba(46, 125, 50, 0.2)',
                            mt: 2
                          }}>
                            <Typography variant="body2" sx={{ mr: 1 }}>
                              {bulkUploadFile.name}
                            </Typography>
                            <IconButton
                              size="small"
                              onClick={() => setBulkUploadFile(null)}
                              disabled={isUploading}
                              sx={{ color: 'text.secondary' }}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </Box>

                      <Divider sx={{ my: 3 }} />

                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                        <Tooltip title="Download a sample CSV template with the required format">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleDownloadTemplate}
                            startIcon={<GetAppIcon />}
                            disabled={isUploading}
                          >
                            Download Template
                          </Button>
                        </Tooltip>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleBulkUpload}
                          disabled={!bulkUploadFile || isUploading}
                          startIcon={isUploading ? <CircularProgress size={20} color="inherit" /> : <CloudUploadIcon />}
                          sx={{ boxShadow: 3, py: 1.2 }}
                        >
                          {isUploading ? 'Uploading...' : 'Upload Students'}
                        </Button>
                      </Box>
                    </FileUploadArea>
                  </LoadingContainer>
                )}
              </StyledCardContent>
            </Paper>
          </Container>
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            variant="filled"
            sx={{ width: '100%', boxShadow: 4 }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>

        <Footer />
        <CustomModal
          isModalVisible={errorModalOpen}
          onPress={() => setErrorModalOpen(false)}
          buttonText="OK"
          modalTitle="Error"
          text="A student account with this ID is already registered in the system. Please use a different Student ID to proceed."
          icon={true}
        />
      </PageWrapper>
    </ThemeProvider>
  );
}