import React, { useEffect, useRef, useState, useCallback } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
// import googleads980 from '../../../assets/img/google-ads-930.jpg';
// import googleads300 from '../../../assets/img/google-ads-300.png';
import Footer from '../../components/Footer/Footer';
import '../../scss/Quiz.scss';
import '../../scss/Result.scss';
import Sparkle from '../../../assets/img/sparkle.svg';
import Info from '../../../assets/img/info.svg';
import ResultImg from '../../../assets/img/result-img.png';
import ResultImgBad from '../../../assets/img/result-image-bad.png';
import usericon from '../../../assets/img/user-img.png';

import Home_icon from '../../../assets/img/home-icon.svg';
import Refreshicon from '../../../assets/img/refresh-icon.png';
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Stack,
  CircularProgress,
} from '@mui/material';

import { authen, db } from '../../firebase/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { resetDetails } from '../../../../../../shared/store/src/lib/actions/questiondetails';
import getQuestionApi from '../../../../../../shared/Utils/GetQuestionApi/Api';
import { setQuestions } from '../../../../../../shared/store/src/lib/actions/question';
import CustomNoResponseModal from '../../components/CustomNoResponseModal';
import LevelUnlock from '../../components/LevelUnlock';
import NewBadges from '../../components/NewBadges';
import { onAuthStateChanged } from '@firebase/auth';
import { doc, getDoc } from '@firebase/firestore';
import { setUserProfile } from 'shared/store/src/lib/actions/userprofile';
import RemoteErrorLog from '../../Utils/api';
import { environment } from 'apps/spellingheroweb/src/environments/environment';

interface FeedbackConfig {
  message: string;
  // animationUrl: string;
  voiceMessage: string;
  style?: React.CSSProperties;
}

// declare global {
//   namespace JSX {
//     interface IntrinsicElements {
//       'lottie-player': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> & {
//         src: string;
//         background?: string;
//         speed?: string;
//         style?: React.CSSProperties;
//         loop?: boolean;
//         autoplay?: boolean;
//       }
//     }
//   }
// }

const Result = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  // const { apiResult, documents, navigated }: any = state || {};
  const savedState = useRef(
    state || JSON.parse(localStorage.getItem('resultState') || '{}')
  );
  const { apiResult, documents, navigated }: any = savedState.current || {};

  const [isLoading, setIsLoading] = useState(true);
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [isBadgeAttained, setBadgeAttained] = useState(false);
  const [isLevelAcquired, setLevelAcquired] = useState(false);
  const filterData = useSelector((state: any) => state.questionfilter);
  const badgesUrl = useSelector((state: any) => state.badgeurls);
  const levelsImageUrl = useSelector((state: any) => state.levelImageUrl);
  const questions = useSelector((state: any) => state.questions);
  const profile = useSelector((state: any) => state.userprofile);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>();
  const [nextBadgeUrl, setNextBadgeUrl] = useState('');
  const [nextLevelUrl, setNextLevelUrl] = useState('');
  const [user, setUser] = useState<any>();
  // const [showAnimation, setShowAnimation] = useState(false);
  
  const feedbackConfigs: { [key: number]: FeedbackConfig } = {
    0: {
      message: "Keep trying! Practice makes perfect.",
      // animationUrl: "/assets/animations/sparkle-animation.json",
      voiceMessage: "Keep trying! Practice makes perfect.",
      style: { width: '500px', height: '400px' }
    },
    1: {
      message: "Keep trying! Practice makes perfect.",
      // animationUrl: "/assets/animations/sparkle-animation.json",
      voiceMessage: "Keep trying! Practice makes perfect.",
      style: { width: '500px', height: '400px' }
    },
    2: {
      message: "You're getting there! Stay focused.",
      // animationUrl: "/assets/animations/flying-balloon.json",
      voiceMessage: "You're getting there! Stay focused.",
      style: { width: '500px', height: '400px' }
    },
    3: {
      message: "Nice effort! You're improving.",
      // animationUrl: "/assets/animations/confetti-explosion-from-center.json",
      voiceMessage: "Nice effort! You're improving.",
      style: { width: '500px', height: '400px' }
    },
    4: {
      message: "Good work! Keep going.",
      // animationUrl: "/assets/animations/thumbs-up.json",
      voiceMessage: "Good work! Keep going.",
      style: { width: '500px', height: '400px' }
    },
    5: {
      message: "Well done! You're halfway there.",
      // animationUrl: "/assets/animations/5-star-animation.json",
      voiceMessage: "Well done! You're halfway there.",
      style: { width: '500px', height: '400px' }
    },
    6: {
      message: "Great job! You're doing well.",
      // animationUrl: "/assets/animations/fireworks.json",
      voiceMessage: "Great job! You're doing well.",
      style: { width: '500px', height: '400px' }
    },
    7: {
      message: "Awesome! You're on a roll.",
      // animationUrl: "/assets/animations/confetti.json",
      voiceMessage: "Awesome! You're on a roll.",
      style: { width: '500px', height: '400px' }
    },
    8: {
      message: "Fantastic! You are on fire.",
      // animationUrl: "/assets/animations/trophy-animation.json",
      voiceMessage: "Fantastic! You are on fire.",
      style: { width: '500px', height: '400px' }
    },
    9: {
      message: "Impressive! Just one more to go.",
      // animationUrl: "/assets/animations/flying-rocket.json",
      voiceMessage: "Impressive! Just one more to go.",
      style: { width: '500px', height: '400px' }
    },
    10: {
      message: "Legendary! You are a Spelling Hero!",
      // animationUrl: "/assets/animations/congress-you-won.json", 
      voiceMessage: "Legendary! You are a Spelling Hero!",
      style: { width: '500px', height: '400px' }
    }
  };

  const [isSoundEnabled, setIsSoundEnabled] = useState(true);
  
  useEffect(() => {
    const checkSoundPreference = () => {
      const savedSoundPreference = localStorage.getItem('GlobalSoundsControl');
      setIsSoundEnabled(savedSoundPreference !== 'OFF');
    };

    checkSoundPreference();

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'GlobalSoundsControl') {
        setIsSoundEnabled(e.newValue !== 'OFF');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const playBubble = useCallback(() => {
    const currentSoundPreference = localStorage.getItem('GlobalSoundsControl');
    if (currentSoundPreference !== 'OFF') {
      try {
        const audio = new Audio('/assets/audio/bubble.mp3');
        audio.play().catch(error => {
          console.warn('Error playing bubble sound:', error);
        });
      } catch (error) {
        console.warn('Error creating audio:', error);
      }
    }
  }, []);

  const handleSeeAnswers = () => {
    playBubble();
    navigate('/quizResult', {
      state: {
        documents: documents,
        navigated: true,
      },
    });
  };

  const handleTryAgain = () => {
    playBubble();
    navigate('/quiz', {
      state: {
        documents: documents,
        navigated: true,
      },
    });
  };

  const handleGoHome = () => {
    playBubble();
    navigate('/home');
  };

  const playVoiceMessage = useCallback((text: string) => {
    const currentSoundPreference = localStorage.getItem('GlobalSoundsControl');
    if (currentSoundPreference !== 'OFF') {
      const speech = new SpeechSynthesisUtterance(text);
      speech.rate = 0.9;
      speech.pitch = 1;
      speech.volume = 0.8;
      window.speechSynthesis.speak(speech);
    }
  }, []);

  const getFeedbackConfig = (correctAnswers: number) => {
    return feedbackConfigs[correctAnswers] || feedbackConfigs[0];
  };

  useEffect(() => {
    if (!isLoading && profile?.user?.metrics?.last_save_words_correct !== undefined) {
      // setShowAnimation(true);
      const feedback = getFeedbackConfig(profile.user.metrics.last_save_words_correct);
      
      setTimeout(() => {
        const currentSoundPreference = localStorage.getItem('GlobalSoundsControl');
        if (currentSoundPreference !== 'OFF') {
          playVoiceMessage(feedback.voiceMessage);
        }
      }, 500);
    }
  }, [isLoading, profile?.user?.metrics?.last_save_words_correct]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser);
      refreshProfile();
    });
    return () => unsubscribe();
  }, [dispatch]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (state) {
      savedState.current = state;
      localStorage.setItem('resultState', JSON.stringify(state));
    }
  }, [state]);
  const refreshProfile = async () => {
    await getUserProfile();
    setIsLoading(false);
  };

  const getUserProfile = async () => {
    try {
      const docRef = doc(
        db,
        'user_profile',
        authen?.currentUser?.uid ? authen?.currentUser?.uid : ''
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        dispatch(setUserProfile(data));
        return data;
      } else {
        return null;
      }
    } catch (error) {
      RemoteErrorLog(error, 'Result.tsx');

      return null;
    }
  };

  useEffect(() => {
    if (profile?.user?.metrics?.badges_attained?.length) {
      setNextBadgeUrl(
        `${
          Object.values(badgesUrl.detail)[
            profile.user?.metrics?.badges_attained?.length - 1
          ]
        }`
      );
    } else {
      badgesUrl?.detail !== null &&
        setNextBadgeUrl(`${Object.values(badgesUrl?.detail)[0]}`);
    }
    if (profile.user?.metrics?.levels_acquired?.length) {
      setNextLevelUrl(
        levelsImageUrl[profile.user?.metrics?.levels_acquired?.length]
      );
    }
  }, [nextBadgeUrl, badgesUrl.detail, nextLevelUrl]);

  useEffect(() => {
    authen.currentUser &&
      !authen.currentUser?.isAnonymous &&
      setBadgeAttained(apiResult?.is_badge_attained);
    authen.currentUser &&
      !authen.currentUser?.isAnonymous &&
      setLevelAcquired(apiResult?.is_level_acquired);
  }, [apiResult, authen.currentUser]);
  useEffect(() => {
    if (navigated !== true) {
      navigate('/home');
    }
  }, [navigated]);
  const hideNoQuestionModal = () => {
    setIsQuestionModalVisible(false);
  };

  const hideBadgeModal = () => {
    setBadgeAttained(false);
  };
  const hideLevelModal = () => {
    setLevelAcquired(false);
  };
  const startNewQuiz = async () => {
    playBubble();
    dispatch(resetDetails());
    setIsLoading(true);
    if (authen.currentUser) {
      const result = await getQuestionApi(
        filterData.result,
        environment?.apiUrl
      );
      if (result == 'Error') {
        setIsQuestionModalVisible(true);
        setApiErrorMessage(
          'We’re currently experiencing some issues. Please try again later.'
        );
        setIsLoading(false);
      } else if (result.error_message !== null) {
        setIsLoading(false);
        setIsQuestionModalVisible(true);
        setApiErrorMessage(result.error_message);
      } else {
        dispatch(setQuestions(result?.words));
        setIsLoading(false);

        navigate('/quiz', { state: { navigated: true } });
      }
    } else {
    }
  };
  return (
    <Box>
      <header>
        <Navbar currentPage="result" />
      </header>
      <Header />

      <main className="page-bg overflow-hidden">
        <Container
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
          }}
        >
          {/* <Box className="googleAds top mx-auto">
            <img src={googleads980} alt="" />
          </Box> */}
          <section>
            <Grid container spacing={3}>
              <Grid item lg={3} xs={12} sx={{ textAlign: 'center' }}>
                {/* <Box className="googleAds top mx-auto">
                <img src={googleads300} alt="" />
              </Box> */}
              </Grid>
              <Grid item lg={6} xs={12}>
                <section className="quiz-step result-step">
                  <Stack spacing={4} direction={'column'}>
                    {isLoading ? (
                      <Box
                        className="item-order-1"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100vh',
                        }}
                      >
                        {' '}
                        <CircularProgress
                          size={56}
                          sx={{
                            color: 'primary.main',
                          }}
                        />{' '}
                      </Box>
                    ) : (
                      <>
                        <Box sx={{ textAlign: 'center', position: 'relative' }}>
                          <Box>
                            <Typography
                              variant="body1"
                              className="font-600 text-congrats"
                              sx={{ fontSize: '36px', color: '#2A9235', marginBottom: '20px' }}
                            >
                              {getFeedbackConfig(profile.user.metrics.last_save_words_correct).message}
                            </Typography>
                            
                            <Box sx={{ 
                              position: 'relative',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}>
                              {(profile.user?.metrics?.last_save_words_correct / questions.length) * 100 > 50 
                                ? <img src={ResultImg} alt="result-img" />
                                : <img src={ResultImgBad} alt="result-img" />
                              }
                              
                              <img
                                src={
                                  authen?.currentUser?.isAnonymous
                                    ? usericon
                                    : profile?.user?.img_uri_https
                                }
                                alt="profilename"
                                className="profile-name"
                                style={{ marginTop: '-10px' }}
                              //   />
                              // </Box>
                              
                              // <Box sx={{ 
                              //   marginTop: '10px',
                              //   display: 'flex', 
                              //   justifyContent: 'center', 
                              //   alignItems: 'center',
                              //   position: 'relative',
                              //   height: '400px',
                              //   overflow: 'hidden',
                              //   marginBottom: '10px'
                              // }}>
                              //   <lottie-player
                              //     src={getFeedbackConfig(profile.user.metrics.last_save_words_correct).animationUrl}
                              //     background="transparent"
                              //     speed="1"
                              //     style={{
                              //       width: '500px', 
                              //       height: '400px',
                              //       position: 'absolute',
                              //       left: '50%',
                              //       top: '50%',
                              //       transform: 'translate(-50%, -50%)'
                              //     }}
                              //     loop
                              //     autoplay
                              />
                            </Box>
                          </Box>

                          {authen.currentUser?.isAnonymous && (
                            <Box className="result-create-account">
                              <Box>
                                <Typography
                                  className="login-result"
                                  variant="h6"
                                  sx={{
                                    color: '#F5F5F5',
                                    fontSize: '22px',
                                    fontFamily: 'Poppins',
                                    fontWeight: '800',
                                  }}
                                >
                                  Don’t lose your progress and earned points!
                                </Typography>

                                <Box
                                  className="result-orange-box"
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    flexWrap: 'nowrap',
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    className="font-600 create-btn-result"
                                    sx={{
                                      color: '#F5F5F5',
                                      fontSize: '16px',
                                      fontFamily: 'Poppins',
                                    }}
                                  >
                                    Create a free account to save your progress!
                                  </Typography>

                                  <Box
                                    sx={{
                                      flexWrap: 'nowrap ',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Button
                                      className="login-btn mr-8"
                                      variant="text"
                                      sx={{
                                        color: '#fff',
                                        textTransform: 'none',
                                        textDecoration: 'underline',
                                        fontSize: '20px',
                                        padding: 'unset',
                                      }}
                                      onClick={() => navigate('/login')}
                                    >
                                      Log in
                                    </Button>
                                    <Button
                                      className="create-btn"
                                      variant="contained"
                                      sx={{
                                        textTransform: 'none',
                                        background: '#fff',
                                        color: '#F68327',
                                        whiteSpace: 'nowrap',
                                        padding: '6px',
                                      }}
                                      onClick={() => navigate('/signup')}
                                    >
                                      Create free account
                                    </Button>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        <Box
                          sx={{
                            textAlign: 'center',
                            marginTop: '20px !important',
                          }}
                        >
                          <Typography
                            variant="body1"
                            className="font-400"
                            sx={{ fontSize: '24px', color: '#333333' }}
                          >
                            You earned{' '}
                            {profile.user?.metrics?.last_save_points_scored}{' '}
                            Points!
                          </Typography>

                          <Typography
                            variant="body1"
                            className="font-600"
                            sx={{ fontSize: '20px', color: '#444444' }}
                          >
                            Your score is
                          </Typography>

                          <Typography
                            variant="body1"
                            className="font-600"
                            sx={{ fontSize: '44px', color: '#444444' }}
                          >
                            <span style={{ color: '#2A9235' }}>
                              {profile.user?.metrics?.last_save_words_correct
                                ? profile.user?.metrics?.last_save_words_correct
                                : 0}
                            </span>
                            /
                            <span style={{ color: '#29B9FF' }}>
                              {questions.length}
                            </span>
                          </Typography>
                        </Box>
                        <Box
                          className="result-btn"
                          sx={{ marginTop: '20px !important' }}
                        >
                          <Button
                            className="btn btn-primary "
                            onClick={handleSeeAnswers}
                          >
                            <span className="btn-icon-result">
                              <img src={Info} alt="info" width={19} />
                              See your answers
                            </span>{' '}
                          </Button>
                          <Button
                            onClick={handleTryAgain}
                            className="btn btn-primary  w-try "
                          >
                            {' '}
                            <span className="btn-icon-result">
                              <img src={Refreshicon} alt="info" width={19} />
                              Try again
                            </span>
                          </Button>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '30px',
                          }}
                        >
                          <Box className="current-level result-current-level">
                            <Box>
                              <Typography
                                variant="h6"
                                className="font-500"
                                sx={{
                                  fontFamily: 'poppins',
                                  color: '#333333',
                                  fontSize: '18px',
                                }}
                              >
                                Current Level:{' '}
                                <Typography
                                  component={'span'}
                                  className="font-500"
                                  style={{ fontStyle: 'italic' }}
                                  sx={{
                                    fontFamily: 'poppins',
                                    color: '#333333',
                                    fontSize: '18px',
                                  }}
                                >
                                  {' '}
                                  {!authen.currentUser?.isAnonymous
                                    ? profile.user?.metrics?.name_level_curr
                                    : '--'}
                                </Typography>
                              </Typography>
                              <Box className="slider-current-level">
                                <Box
                                  className="customprogress"
                                  sx={{ width: 'unset' }}
                                >
                                  {!authen.currentUser?.isAnonymous && (
                                    <Typography className="custmPrgsTxtunfill">
                                      {(profile.user?.metrics
                                        ?.next_level_points_scored /
                                        profile.user?.metrics
                                          ?.next_level_points_total) *
                                        100 <=
                                        70 &&
                                        `${profile.user?.metrics?.next_level_points_total} Points`}
                                    </Typography>
                                  )}

                                  <Box
                                    className="innerFill resultfill"
                                    sx={{
                                      width: !authen.currentUser?.isAnonymous
                                        ? `${
                                            (profile.user?.metrics
                                              ?.next_level_points_scored /
                                              profile.user?.metrics
                                                ?.next_level_points_total) *
                                              100 <=
                                            3
                                              ? 3
                                              : (profile.user?.metrics
                                                  ?.next_level_points_scored /
                                                  profile.user?.metrics
                                                    ?.next_level_points_total) *
                                                100
                                          }%`
                                        : '3%',
                                    }}
                                  >
                                    {!authen.currentUser?.isAnonymous && (
                                      <Typography className="custmPrgsTxt">
                                        {(profile.user?.metrics
                                          ?.next_level_points_scored /
                                          profile.user?.metrics
                                            ?.next_level_points_total) *
                                          100 >=
                                          22 &&
                                          `${profile.user?.metrics?.next_level_points_scored} Points`}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              </Box>

                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: 'poppins',
                                  color: '#2A9235',
                                  fontSize: '12px',
                                  fontWeight: '600',
                                }}
                              >
                                You need just{' '}
                                {!authen.currentUser?.isAnonymous
                                  ? profile.user?.metrics
                                      ?.next_level_points_remaining
                                  : '--'}{' '}
                                more points to reach the next level!
                              </Typography>
                            </Box>
                          </Box>

                          <Box className="current-level result-current-level">
                            <Box>
                              <Typography
                                variant="h6"
                                className="font-500"
                                sx={{
                                  fontFamily: 'poppins',
                                  color: '#333333',
                                  fontSize: '18px',
                                }}
                              >
                                Current Badge:{' '}
                                {!authen.currentUser?.isAnonymous
                                  ? profile?.user?.metrics?.name_badge_curr
                                  : '--'}
                              </Typography>
                              <Box className="slider-current-level">
                                <Box
                                  className="customprogress"
                                  sx={{ width: 'unset' }}
                                >
                                  {!authen.currentUser?.isAnonymous && (
                                    <Typography className="custmPrgsTxtunfill">
                                      {(profile.user?.metrics
                                        ?.next_badge_words_scored /
                                        profile.user?.metrics
                                          ?.next_badge_words_total) *
                                        100 <=
                                        70 &&
                                        `${profile.user?.metrics?.next_badge_words_total} Words`}
                                    </Typography>
                                  )}

                                  <Box
                                    className="innerFill"
                                    sx={{
                                      width: !authen.currentUser?.isAnonymous
                                        ? `${
                                            (profile.user?.metrics
                                              ?.next_badge_words_scored /
                                              profile.user?.metrics
                                                ?.next_badge_words_total) *
                                              100 <=
                                            3
                                              ? 3
                                              : (profile.user?.metrics
                                                  ?.next_badge_words_scored /
                                                  profile.user?.metrics
                                                    ?.next_badge_words_total) *
                                                100
                                          }%`
                                        : '3%',
                                    }}
                                  >
                                    {!authen.currentUser?.isAnonymous && (
                                      <Typography className="custmPrgsTxt">
                                        {(profile.user?.metrics
                                          ?.next_badge_words_scored /
                                          profile.user?.metrics
                                            ?.next_badge_words_total) *
                                          100 >=
                                          18 &&
                                          `${profile.user?.metrics?.next_badge_words_scored} Words`}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                              {/* <Box className="slider-current-level">
                          <Box className="custmProgess ">
                            <Box
                              className="innerFill"
                              style={{
                                width: !authen.currentUser?.isAnonymous
                                  ? `${
                                      (profile.user?.metrics
                                        ?.next_badge_words_scored /
                                        profile.user?.metrics
                                          ?.next_badge_words_total) *
                                      100
                                    }%`
                                  : '',
                              }}
                            >
                              {!authen.currentUser?.isAnonymous && (
                                <Typography className="custmPrgsTxt">
                                  {(profile.user?.metrics
                                    ?.next_badge_words_scored /
                                    profile.user?.metrics
                                      ?.next_badge_words_total) *
                                    100 >=
                                    22 &&
                                    `${profile.user?.metrics?.next_badge_words_scored} Points`}
                                </Typography>
                              )}

                              <img
                                src={LevelStar}
                                alt="Star"
                                width={20}
                                height={20}
                              />
                            </Box>

                            {!authen.currentUser?.isAnonymous && (
                              <Typography className="custmPrgsTxtunfill">
                                {(profile.user?.metrics
                                  ?.next_badge_words_scored /
                                  profile.user?.metrics
                                    ?.next_badge_words_total) *
                                  100 <=
                                  88 &&
                                  `${profile.user?.metrics?.next_badge_words_total} Points`}
                              </Typography>
                            )}
                          </Box>
                        </Box> */}

                              <Typography
                                variant="body1"
                                sx={{
                                  fontFamily: 'poppins',
                                  color: '#2A9235',
                                  fontSize: '12px',
                                  fontWeight: '600',
                                }}
                              >
                                {`You’re just ${
                                  !authen.currentUser?.isAnonymous
                                    ? profile.user?.metrics
                                        ?.next_badge_words_remaining
                                    : '--'
                                } correct answers away from earning your next badge!`}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <Box className="result-btn">
                          <Button
                            onClick={startNewQuiz}
                            className="btn btn-primary "
                          >
                            <span className="btn-icon-result">
                              <img src={Sparkle} alt="info" width={19} />
                              Start new quiz
                            </span>{' '}
                          </Button>
                          <Button
                            className="btn btn-primary "
                            onClick={handleGoHome}
                          >
                            {' '}
                            <span className="btn-icon-result">
                              <img src={Home_icon} alt="info" width={19} />
                              Go to home
                            </span>
                          </Button>
                        </Box>
                      </>
                    )}
                  </Stack>
                </section>
              </Grid>
              <Grid
                item
                lg={3}
                xs={12}
                className="pb-3"
                sx={{ textAlign: 'center' }}
              >
                {/* <Box className="googleAds top mx-auto">
                <img src={googleads300} alt="" />
              </Box> */}
              </Grid>
            </Grid>
          </section>
        </Container>
        <CustomNoResponseModal
          isModalVisible={isQuestionModalVisible}
          onPress={hideNoQuestionModal}
          buttonText="OK"
          modalTitle="Oops!"
          text={apiErrorMessage}
        />
        <LevelUnlock
          currentLevelUrl={nextLevelUrl}
          isModalVisible={isLevelAcquired}
          onPress={hideLevelModal}
          buttonText="Continue Learning"
          modalTitle="New level unlocked"
          text={`Congrats, ${profile?.user?.full_name}! You just unlocked new level`}
          text2={apiResult?.name_level_acquired}
        />
        <NewBadges
          currentBadgeUrl={nextBadgeUrl}
          isModalVisible={isBadgeAttained}
          onPress={hideBadgeModal}
          buttonText="Continue Learning"
          modalTitle="New badge acquired"
          text={`Congrats, ${profile?.user?.full_name}! You just completed ${
            apiResult?.name_badge_attained?.split(' ')[0]
          } correct word streak`}
          text2={apiResult?.name_badge_attained}
          wordNumber={apiResult?.name_badge_attained?.split(' ')[0]}
        />
      </main>
      <Footer />
    </Box>
  );
};

export default Result;
