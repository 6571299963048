import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import erroricon from '../../assets/img/error.png';

interface TrialExpiryModalProps {
  isModalVisible: boolean;
  onClose: () => void;
  text?: string;
  modalTitle?: string;
  showMembershipOption?: boolean;
  cancelButtonText?: string;
  continueButtonText?: string;
}

const TrialExpiryModal: React.FC<TrialExpiryModalProps> = ({
  isModalVisible,
  onClose,
  text = 'Your free trial has expired. Please contact your school or teacher to purchase a subscription for you.',
  modalTitle = 'Oops!',
  showMembershipOption = true,
  cancelButtonText = 'Cancel',
  continueButtonText = 'Continue'
}) => {
  const navigate = useNavigate();

  const handleContinue = (): void => {
    onClose();
    if (showMembershipOption) {
      navigate('/profile', {
        state: {
          openMembershipTab: true,
          tabIndex: 3
        }
      });
    }
  };

  return (
    <Dialog
      open={isModalVisible}
      onClose={onClose}
      aria-labelledby="trial-expiry-dialog"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="trial-expiry-dialog" style={{ textAlign: 'center' }}>
        <img
          src={erroricon}
          className="mx-auto d-block mb-2"
          width={60}
          alt="Error Icon"
        />
        {modalTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ textAlign: 'center' }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions 
        style={{ 
          padding: '16px 24px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Button 
          onClick={onClose} 
          color="primary" 
          variant="outlined"
          style={{ 
            minWidth: '120px',
            textTransform: 'none'
          }}
        >
          {cancelButtonText}
        </Button>
        {showMembershipOption && (
          <Button 
            onClick={handleContinue} 
            color="primary" 
            variant="contained"
            style={{ 
              minWidth: '120px',
              textTransform: 'none'
            }}
          >
            {continueButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TrialExpiryModal;