import React, { useState, MouseEvent, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  MenuItem,
  Container,
  Avatar,
  Button,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  SvgIcon,
} from '@mui/material';
import Logoapp from '../../../assets/img/logoapp.png';
import usericon from '../../../assets/img/user-img.png';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.scss';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { authen } from '../../firebase/firebase';
import ConfirmModal from '../ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { setUserProfile } from 'shared/store/src/lib/actions/userprofile';
import { formatName } from 'shared/functions/home';

import { ReactComponent as Hamburger } from '../../../assets/img/hamburger.svg';
import CustomDeleteModal from '../CustomDeleteModal';
import deleteAccountApi from 'shared/Utils/DeleteUserApi/Api';
import CustomNoResponseModal from '../CustomNoResponseModal';
import RemoteErrorLog from '../../Utils/api';
import { setFilter } from 'shared/store/src/lib/actions/filter';
import { setBadge } from 'shared/store/src/lib/actions/badges';
import { setLevelImage } from 'shared/store/src/lib/actions/levelsImage';
import { setAvatar } from 'shared/store/src/lib/actions/avatars';
import { setProduct } from 'shared/store/src/lib/actions/products';
import { isuserPremium } from '../../Utils/firebase-services';
import { environment } from 'apps/spellingheroweb/src/environments/environment';
export default function Navbar({ currentPage }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [
    isSubscriptionDeleteModalVisible,
    setIsSubscriptionDeleteModalVisible,
  ] = useState(false);

  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [isCookiePolicyModalVisible, setIsCookiePolicyModalVisible] =
    useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const profile = useSelector((state: any) => state.userprofile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogoutClick = () => {
    setOpen(true);
  };
  const handleDeleteClick = () => {
    setIsModalVisible(true);
  };
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser); // Update the user state
      if (!currentUser) {
        localStorage.removeItem('userData');
      }
      if (currentUser == null) {
        localStorage.removeItem('userData');
      }
    });
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [dispatch]);
  useEffect(() => {
    if (localStorage.getItem('userData')) {
      const data = localStorage.getItem('userData') ?? '{}';
      setUserData(JSON.parse(data));
    }
  }, [localStorage.getItem('userData')]);
  useEffect(() => {
    if (currentPage !== 'result') {
      localStorage.removeItem('resultState');
    } else if (currentPage !== 'quiz') {
      localStorage.removeItem('quizState');
    } else if (currentPage !== 'quizResult') {
      localStorage.removeItem('quizResultState');
    }
  }, [currentPage]);
  const signOutUser = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const questionInfo = {
      uid: '',
      mode: '',
      level: '',
      filter_type: '',
      filter_value: '',
      include_incorrect: '',
    };
    if (user !== null) {
      localStorage.removeItem('userData');
      localStorage.removeItem('pronunciationMode');
      localStorage.removeItem('userSpellingPreference');
      localStorage.removeItem('isLoggedInViaClever');
      await authen.signOut();
      setUserData({});
      handleClose();

      await dispatch(setFilter(questionInfo));
      await dispatch(setUserProfile(''));
      await dispatch(setBadge(null));
      await dispatch(setLevelImage([]));
      await dispatch(setAvatar([]));
      await dispatch(setProduct([]));

      navigate('/login');
      //   navigation.reset({
      //     routes: [{name: 'Login'}],
      //   });
    } else {
      localStorage.removeItem('userData');
      // await authen.signOut();
      await dispatch(setFilter(questionInfo));
      await dispatch(setUserProfile(''));
      await dispatch(setBadge(null));
      await dispatch(setLevelImage([]));
      await dispatch(setAvatar([]));
      await dispatch(setProduct([]));

      setUserData({});
      handleClose();
      navigate('/login');
    }
  };
  // const cookieApiCall = async (cookieInfo: any) => {
  //   const token = await (authen?.currentUser as any).getIdToken(true);

  //   const result = await cookiePreferences(cookieInfo, token);
  //   // setIsLoading(false);
  //   // navigate('/');
  // };
  // const hideCookieModal = async () => {
  //   // setIsCookieModalVisible(false);
  //   setIsCookiePolicyModalVisible(false);

  //   try {
  //     const updateProfileType = {
  //       uid: userData?.uid,
  //       cookies_info: {
  //         preferences: {
  //           is_essential: true,
  //           is_session: true,
  //           is_authentication: true,
  //           is_analytics: true,
  //           is_advertising: true,
  //         },
  //       },
  //     };
  //     const updateUser = await updateUserProfile(updateProfileType);
  //   } catch (error) {
  //     console.error(error);
  //     RemoteErrorLog(error, 'Navbar.tsx');
  //   }

  //   try {
  //     const docRef = doc(db, 'user_profile', user?.uid);
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       const data = docSnap.data();
  //       dispatch(setUserProfile(data));
  //       return data;
  //     } else {
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error('Error getting document:', error);
  //     RemoteErrorLog(error, 'Navbar.tsx');

  //     return null;
  //   }
  // };
  // const onSavePreference = async () => {
  //   setIsCookiePolicyModalVisible(false);
  //   try {
  //     const docRef = doc(db, 'user_profile', user?.uid);
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       const data = docSnap.data();
  //       dispatch(setUserProfile(data));
  //       return data;
  //     } else {
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error('Error getting document:', error);
  //     RemoteErrorLog(error, 'Navbar.tsx');

  //     return null;
  //   }

  //   //   navigate('/');
  // };

  const handleDeleteAccount = async () => {
    let value = await isuserPremium();
    if (value) {
      setIsModalVisible(false);
      setIsSubscriptionDeleteModalVisible(true);
    } else {
      deleteAccount();
    }
  };
  const deleteAccount = async () => {
    const user = authen.currentUser;
    const decodedToken: any = await authen?.currentUser?.getIdTokenResult();

    if (user) {
      try {
        const userInfo = {
          uid: user?.uid,
          revCatSubscription:
            decodedToken?.claims?.revenueCatEntitlements &&
            decodedToken?.claims?.revenueCatEntitlements?.[0]
              ? true
              : false,
        };

        const token = await (authen?.currentUser as any).getIdToken(true);

        const result = await deleteAccountApi(
          userInfo,
          token,
          environment?.apiUrl
        );
        setIsModalVisible(false);

        if (result == 'Error') {
          setIsQuestionModalVisible(true);
          setApiErrorMessage(
            'We’re currently experiencing some issues. Please try again later.'
          );
          setIsModalVisible(false);
          // setIsLoading(false);
        } else if (!result?.error_message) {
          localStorage.removeItem('userData');
          const questionInfo = {
            uid: '',
            mode: '',
            level: '',
            filter_type: '',
            filter_value: '',
            include_incorrect: '',
          };
          await dispatch(setFilter(questionInfo));
          await dispatch(setUserProfile(''));
          await dispatch(setBadge(null));
          await dispatch(setLevelImage([]));
          await dispatch(setAvatar([]));
          await dispatch(setProduct([]));
          navigate('/login');
        } else {
          setApiErrorMessage(result?.error_message);
          setIsQuestionModalVisible(true);
          setIsModalVisible(false);
        }
      } catch (error) {
        RemoteErrorLog(error, 'Navbar.tsx');
      }
    } else {
      window.alert('User not found.');
    }
  };
  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSubMenuToggle = () => {
    setSubMenuOpen(!subMenuOpen);
  };
  const hideModal = () => {
    setIsModalVisible(false);
  };
  const hideSubscriptionDeleteModal = () => {
    setIsSubscriptionDeleteModalVisible(false);
  };

  const hideNoQuestionModal = () => {
    setIsQuestionModalVisible(false);
  };

  return (
    <>
      <main>
        <AppBar
          position="fixed"
          color="default"
          sx={{ background: '#fff' }}
          className="navbar-new-section"
        >
          <Container
            sx={{
              maxWidth: { xs: '100%', sm: '100%', md: '1460px' },
            }}
          >
            <Toolbar disableGutters>
              <Link to="/" className="logo-company">
                <img src={Logoapp} alt="Logo" style={{ marginRight: 16 }} />
              </Link>
              <Box
                sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                className="navbar-content"
              >
                <Button
                  component={Link}
                  to="/home"
                  style={{ color: currentPage == 'home' ? 'green' : '' }}
                  sx={{ my: 2 }}
                  // disabled={
                  //   userData?.isAnonymous == null ||
                  //   currentPage == 'signup' ||
                  //   currentPage == 'login'
                  //     ? true
                  //     : false
                  // }
                >
                  Home
                </Button>
                <Button
                  component={Link}
                  to="/myProgress"
                  sx={{ my: 2 }}
                  // disabled={
                  //   currentPage == 'signup' ||
                  //   currentPage == 'login' ||
                  //   userData?.isAnonymous == null
                  //     ? true
                  //     : false
                  // }
                  style={{ color: currentPage == 'myprogress' ? 'green' : '' }}
                >
                  My progress
                </Button>
                <Button
                  component={Link}
                  to="/profile"
                  sx={{ my: 2 }}
                  // disabled={
                  //   currentPage == 'signup' ||
                  //   currentPage == 'login' ||
                  //   userData?.isAnonymous == null
                  //     ? true
                  //     : false
                  // }
                  style={{ color: currentPage == 'myprofile' ? 'green' : '' }}
                >
                  My profile
                </Button>
                <Button
                  component={Link}
                  to="/signup"
                  sx={{ my: 2 }}
                  style={{ color: currentPage == 'signup' ? 'green' : '' }}
                >
                  Sign up
                </Button>
                {userData?.isAnonymous == true ||
                userData?.isAnonymous == null ? (
                  <Button
                    component={Link}
                    to="/login"
                    style={{ color: currentPage == 'login' ? 'green' : '' }}
                  >
                    Login
                  </Button>
                ) : (
                  <Button
                    component={Link}
                    to=""
                    onClick={handleLogoutClick}
                    sx={{ my: 2 }}
                  >
                    Logout
                  </Button>
                )}
                <Button
                  component={Link}
                  onClick={handleDeleteClick}
                  to=""
                  sx={{ my: 2 }}
                  disabled={
                    userData?.isAnonymous == true ||
                    currentPage == 'signup' ||
                    currentPage == 'login' ||
                    userData?.isAnonymous == null
                      ? true
                      : false
                  }
                >
                  Delete account
                </Button>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="h6"
                    noWrap
                    sx={{
                      marginRight: 2,
                      color: '#2A9235',
                      fontSize: {
                        xs: 12,
                        sm: '1.25rem',
                      },
                    }}
                  >
                    {currentPage == 'login' ||
                    currentPage == 'signup' ||
                    userData?.isAnonymous == null
                      ? ''
                      : userData?.isAnonymous
                      ? 'Guest'
                      : formatName(profile?.user?.full_name)}
                  </Typography>
                  <Avatar
                    src={
                      userData?.isAnonymous == null || user?.isAnonymous == null
                        ? usericon
                        : userData?.isAnonymous
                        ? usericon
                        : profile?.user?.img_uri_https
                    }
                    alt="User Icon"
                  />
                  <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="menu"
                    onClick={handleMenuOpen}
                    sx={{ display: { md: 'none' } }}
                  ></IconButton>
                  <IconButton
                    size="large"
                    edge="end"
                    color="inherit"
                    aria-label="more"
                    onClick={handleSubMenuToggle}
                  >
                    <SvgIcon sx={{ marginTop: 2 }}>
                      <Hamburger />
                    </SvgIcon>
                  </IconButton>
                </Box>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

        {subMenuOpen && (
          <Box
            sx={{
              position: 'fixed',
              top: '64px',
              width: '100%',
              backgroundColor: '#EBEBEB',
              boxShadow: 3,
              zIndex: 1100,
            }}
          >
            <Container>
              <Box className="scroll-navbar">
                <Box className="navbar-top-content">
                  <MenuItem
                    component={Link}
                    to="/home"
                    style={{ color: currentPage == 'home' ? 'green' : '' }}
                    onClick={handleMenuClose}
                    // disabled={
                    //   userData?.isAnonymous == null ||
                    //   currentPage == 'signup' ||
                    //   currentPage == 'login'
                    //     ? true
                    //     : false
                    // }
                  >
                    Home
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/myProgress"
                    onClick={handleMenuClose}
                    // disabled={
                    //   currentPage == 'signup' ||
                    //   currentPage == 'login' ||
                    //   userData?.isAnonymous == null
                    //     ? true
                    //     : false
                    // }
                    style={{
                      color: currentPage == 'myprogress' ? 'green' : '',
                    }}
                  >
                    My progress
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to="/profile"
                    onClick={handleMenuClose}
                    // disabled={
                    //   currentPage == 'signup' ||
                    //   currentPage == 'login' ||
                    //   userData?.isAnonymous == null
                    //     ? true
                    //     : false
                    // }
                    style={{ color: currentPage == 'myprofile' ? 'green' : '' }}
                  >
                    My profile
                  </MenuItem>
                  {userData?.isAnonymous == true ||
                  userData?.isAnonymous == null ? (
                    <MenuItem
                      component={Link}
                      to="/login"
                      style={{ color: currentPage == 'login' ? 'green' : '' }}
                    >
                      Login
                    </MenuItem>
                  ) : (
                    <MenuItem
                      component={Link}
                      to=""
                      onClick={handleLogoutClick}
                    >
                      Logout
                    </MenuItem>
                  )}

                  <MenuItem
                    component={Link}
                    to=""
                    onClick={handleDeleteClick}
                    disabled={
                      currentPage == 'signup' ||
                      currentPage == 'login' ||
                      userData?.isAnonymous == true ||
                      userData?.isAnonymous == null
                        ? true
                        : false
                    }
                  >
                    Delete account
                  </MenuItem>
                </Box>
                <List
                  className="navbar-upper-content-list"
                  sx={{
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                    whiteSpace: 'nowrap',
                    color: '#5E5E5E',
                  }}
                >
                  <ListItem
                    button
                    component={Link}
                    to="/about-us"
                    onClick={handleSubMenuToggle}
                    style={{
                      color: currentPage == 'aboutus' ? 'green' : '',
                    }}
                  >
                    <ListItemText primary="About us" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/privacy-policy"
                    onClick={handleSubMenuToggle}
                    style={{
                      color: currentPage == 'privacypolicy' ? 'green' : '',
                    }}
                  >
                    <ListItemText primary="Privacy Policy" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/terms-of-service"
                    onClick={handleSubMenuToggle}
                    style={{
                      color: currentPage == 'termsofservice' ? 'green' : '',
                    }}
                  >
                    <ListItemText primary="Terms & Conditions" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/profile/3"
                    // disabled={
                    //   userData?.isAnonymous == true ||
                    //   userData?.isAnonymous == null
                    //     ? true
                    //     : false
                    // }
                    onClick={handleSubMenuToggle}
                  >
                    <ListItemText primary="My Membership" />
                  </ListItem>
                  <ListItem
                    button
                    component={Link}
                    to="/change-password"
                    // disabled={
                    //   userData?.isAnonymous == true ||
                    //   userData?.isAnonymous == null
                    //     ? true
                    //     : false
                    // }
                    style={{
                      color: currentPage == 'changePassword' ? 'green' : '',
                    }}
                    onClick={handleSubMenuToggle}
                  >
                    <ListItemText primary="Change password" />
                  </ListItem>
                  {/* <ListItem
                  button
                  component={Link}
                  to=""
                  disabled={userData?.uid ? false : true}
                  onClick={() => {
                    setIsCookiePolicyModalVisible(true);
                  }}
                >
                  <ListItemText primary="Manage cookies" />
                </ListItem> */}
                  <ListItem
                    button
                    component={Link}
                    to="/contact-us"
                    style={{
                      color: currentPage == 'contactus' ? 'green' : '',
                    }}
                    onClick={handleSubMenuToggle}
                  >
                    <ListItemText
                      primary="Contact us"
                      className="List-details"
                    />
                  </ListItem>
                </List>
              </Box>
            </Container>
          </Box>
        )}
        <ConfirmModal
          open={open}
          onClose={handleClose}
          onConfirm={signOutUser}
        />

        {/* <CookiePolicyModal
        // firebaseUserProvider={firebaseUserProvider}
        firebaseUser={{
          uid: user?.uid,
          displayName: profile?.user?.full_name,
          isAnonymous: user?.isAnonymous,
        }}
        // navigation={navigation}
        isModalVisible={isCookiePolicyModalVisible}
        onAccept={hideCookieModal}
        onSavePreference={onSavePreference}
        handleClose={() => setIsCookiePolicyModalVisible(false)}
        ScreenName={'Navbar'}
      /> */}
        <CustomDeleteModal
          isModalVisible={isModalVisible}
          onPressbutton1={hideModal}
          onPressbutton2={handleDeleteAccount}
          buttonText1="No"
          buttonText2="Yes"
          modalTitle="Delete Account"
          text="This will delete all your data, including learning history. Once you delete your account, we cannot recover it. It will be gone forever."
          text2="Are you sure you want to continue?"
        />
        <CustomDeleteModal
          isModalVisible={isSubscriptionDeleteModalVisible}
          onPressbutton1={hideSubscriptionDeleteModal}
          onPressbutton2={deleteAccount}
          buttonText1="No"
          buttonText2="Yes"
          modalTitle="Delete Account"
          text="If you proceed with the deletion, we will cancel your purchased ad-free membership, and reactivation won’t be possible. Additionally, any remaining paid funds, valid until the end of the billing cycle, will be forfeited."
          text2="Are you sure you want to continue?"
        />
        <CustomNoResponseModal
          isModalVisible={isQuestionModalVisible}
          onPress={hideNoQuestionModal}
          buttonText="OK"
          modalTitle="Oops!"
          text={apiErrorMessage}
        />
      </main>
    </>
  );
}
