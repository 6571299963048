import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import './TabProfile.scss';
import LockIcon from '@mui/icons-material/Lock';
// import Leaderboardpic_one from '../../../assets/img/leaderboardpic-1.png';
import SpellingLogo from '../../../assets/img/spellingapp-logo.svg';
// import Leaderboardimg2 from '../../../assets/img/leaderboardprofile-pic.png';
import { CircularProgress, Divider } from '@mui/material';
// import Leaderboardradiobtn from '../LeaderboardRadiobtn/LeaderboardRadiobtn';
import { authen, db, functions, storage } from '../../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import GetLevelAndPoints from '../../../../../../shared/Utils/GetLevelAndPointsApi/Api';
import { setLevel } from '../../../../../../shared/store/src/lib/actions/levels';
import { setAvatar } from '../../../../../../shared/store/src/lib/actions/avatars';
import { setProduct } from '../../../../../../shared/store/src/lib/actions/products';

// import getLeaderBoardValues from '../../../../../../shared/Utils/LeaderBoardApi/Api';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import RemoteErrorLog from '../../Utils/api';
import CustomNoResponseModal from '../CustomNoResponseModal';
import { httpsCallable } from 'firebase/functions';
import { useParams } from 'react-router-dom';
import { collection, getDocs } from '@firebase/firestore';
// import { collection, getDocs } from '@firebase/firestore';
import MembershipTab from '../MemebershipTab/MembershipTab';
import { environment } from 'apps/spellingheroweb/src/environments/environment';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
interface props {
  tabIndex: number;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
}
export default function TabProfile({ tabIndex, setTabIndex }: props) {
  const [value, setValue] = React.useState(0);
  const [user, setUser] = useState<any>();
  const profile = useSelector((state: any) => state.userprofile);
  const dispatch = useDispatch();
  const level = useSelector((state: any) => state.levelandpoints);
  const product = useSelector((state: any) => state.products);

  const avatarImageurls = useSelector((state: any) => state.avatarurls);
  const levelsImageUrl = useSelector((state: any) => state.levelImageUrl);
  const badgesUrl = useSelector((state: any) => state.badgeurls);
  const [imageTab, setImageTab] = useState<any>([]);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>();
  const [isQuestionModalVisible, setIsQuestionModalVisible] = useState(false);
  const [levelPoints, setLevelPoints] = useState({});
  // const [sevenDayPlayers, setSevenDayPlayers] = useState<any>({});
  // const [fourteenDayPlayers, setFourteenDayPlayers] = useState<any>({});
  const [currentDate, setCurrentDate] = useState('');
  const [sevenDate, setSevenDate] = useState('');
  const [fourteenDate, setFourteenDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isManageSubscriptionLoading, setIsManageSubscriptionLoading] =
    useState(false);

  // const [selectedOption, setSelectedOption] = useState('7days');
  const [subscription, setSubscription] = useState('');

  const [isPremium, setIsPremium] = useState<any>(null);
  const { tabId } = useParams();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser); // Update the user state
      const checkPremium = async () => {
        const token = await (authen?.currentUser as any)?.getIdToken(true);
        const decodedToken: any = await authen?.currentUser?.getIdTokenResult();

        const value = decodedToken?.claims?.stripeRole
          ? true
          : false ||
            (decodedToken?.claims?.revenueCatEntitlements &&
              decodedToken?.claims?.revenueCatEntitlements?.[0])
          ? true
          : false;
        let subscription = '';
        setIsPremium(value);
        if (value) {
          if (decodedToken?.claims?.stripeRole) {
            subscription = decodedToken?.claims?.stripeRole.includes('monthly')
              ? 'Monthly'
              : decodedToken?.claims?.stripeRole.includes('quarterly')
              ? 'Quarterly'
              : 'Yearly';
            setSubscription(subscription);
          } else if (decodedToken?.claims?.revenueCatEntitlements?.[0]) {
            subscription =
              decodedToken?.claims?.revenueCatEntitlements?.[0].includes(
                'monthly'
              )
                ? 'Monthly'
                : decodedToken?.claims?.revenueCatEntitlements?.[0].includes(
                    'quarterly'
                  )
                ? 'Quarterly'
                : 'Yearly';
            setSubscription(subscription);
          }
        }
      };
      checkPremium();
    });
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const [isSoundEnabled, setIsSoundEnabled] = useState(true);
  
  useEffect(() => {
    const checkSoundPreference = () => {
      const savedSoundPreference = localStorage.getItem('GlobalSoundsControl');
      setIsSoundEnabled(savedSoundPreference !== 'OFF');
    };

    checkSoundPreference();

    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'GlobalSoundsControl') {
        setIsSoundEnabled(e.newValue !== 'OFF');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const playBubble = useCallback(() => {
    const currentSoundPreference = localStorage.getItem('GlobalSoundsControl');
    if (currentSoundPreference !== 'OFF') {
      try {
        const audio = new Audio('/assets/audio/bubble.mp3');
        audio.play().catch(error => {
          console.warn('Error playing bubble sound:', error);
        });
      } catch (error) {
        console.warn('Error creating audio:', error);
      }
    }
  }, []);

  useEffect(() => {
    setValue(tabId ? Number(tabId) : 0);
  }, [tabId]);

  useEffect(() => {
    if (tabIndex == 3 && value !== 3) {
      setValue(3);
    }
  }, [tabIndex]);
  const fetchLevelPointData = async () => {
    try {
      const pointlevel = await GetLevelAndPoints(environment?.apiUrl);
      if (pointlevel == 'Error') {
        setIsQuestionModalVisible(true);
        setApiErrorMessage(
          'We’re currently experiencing some issues. Please try again later.'
        );

        // setIsLoading(false);
      } else {
        dispatch(setLevel(pointlevel?.mapping_points_level));
        setLevelPoints(pointlevel?.mapping_points_level);
      }
    } catch (error) {
      RemoteErrorLog(error, 'TabProfile.tsx');
    }
  };

  useEffect(() => {
    if (!product.length) {
      fetchPrices();
    }
  }, []);
  const fetchPrices = async () => {
    try {
      const productsRef = collection(db, 'products');
      const querySnapshot = await getDocs(productsRef);

      const allPrices: any = [];

      const priceFetchPromises = querySnapshot.docs.map(async (productDoc) => {
        const productData = productDoc.data();
        if (productData.active) {
          const pricesRef = collection(productDoc.ref, 'prices');
          const pricesSnapshot = await getDocs(pricesRef);

          const productPrices = pricesSnapshot.docs.map((priceDoc) => ({
            productId: productDoc.id,
            priceId: priceDoc.id,
            price: priceDoc.data(),
            product: productDoc.data(),
          }));

          allPrices.push(...productPrices);
        }
      });

      await Promise.all(priceFetchPromises);

      dispatch(setProduct(allPrices));
    } catch (error) {
      RemoteErrorLog(error, 'TabProfile.tsx');
    }
  };

  //The below code would be used in future for Leaderboard
  // const fetchLeaderBoardData = async () => {
  //   try {
  //     // const appToken = await fetchAppCheckToken();
  //     if (currentDate && sevenDate && fourteenDate) {
  //       const LeaderboardInfoSeven = {
  //         uid: authen?.currentUser?.uid ? authen?.currentUser?.uid : '',
  //         start_dt: sevenDate,
  //         end_dt: currentDate,
  //       };
  //       const LeaderboardInfoFourteen = {
  //         uid: authen?.currentUser?.uid ? authen?.currentUser?.uid : '',
  //         start_dt: fourteenDate,
  //         end_dt: currentDate,
  //       };
  //

  //       const pointlevelSeven = await getLeaderBoardValues(
  //         LeaderboardInfoSeven
  //       );
  //       setSevenDayPlayers(pointlevelSeven?.data?.rec_leaderboard);
  //       const pointlevelFourteen = await getLeaderBoardValues(
  //         LeaderboardInfoFourteen
  //       );
  //       setFourteenDayPlayers(pointlevelFourteen?.data?.rec_leaderboard);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     RemoteErrorLog(error, 'TabProfile.tsx');
  //   }
  // };

  useEffect(() => {
    const fetechLevel = () => {
      if (level?.result) {
        setLevelPoints(level?.result);
      } else {
        fetchLevelPointData();
      }
    };

    const fetchAvatars = async () => {
      if (!avatarImageurls.length) {
        try {
          try {
            const storageRef = await ref(storage, 'web_images/avatar');
            const { items } = await listAll(storageRef);

            const downloadPromises = items.map((item) => getDownloadURL(item));
            const urls = await Promise.all(downloadPromises);

            setImageTab(urls);
            // setHasImagesLoaded(true);
            dispatch(setAvatar(urls));
          } catch (error) {
            RemoteErrorLog(error, 'TabProfile.tsx');

            // Handle error appropriately, e.g., show an error message to the user
          }
        } finally {
        }
      } else {
        setImageTab(avatarImageurls);
      }
    };
    Promise.all([fetchAvatars(), fetechLevel(), todayDate()]).then(() => {});
  }, []);

  const todayDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JavaScript
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    setCurrentDate(formattedDate);
    const formattedDateSevenDaysAgo = `${year}-${month}-${String(
      currentDate.getDate() - 7
    ).padStart(2, '0')}`;
    setSevenDate(formattedDateSevenDaysAgo);
    const formattedDateFourteenDaysAgo = `${year}-${month}-${String(
      currentDate.getDate() - 14
    ).padStart(2, '0')}`;
    setFourteenDate(formattedDateFourteenDaysAgo);
  };
  useEffect(() => {
    if (
      Object.keys(levelPoints).length !== 0 &&
      badgesUrl.detail &&
      imageTab.length !== 0
    ) {
      setIsLoading(false);
    }
  }, [badgesUrl.detail, levelPoints, imageTab]);

  const manageMembership = async () => {
    setIsManageSubscriptionLoading(true);

    const decodedToken: any = await authen?.currentUser?.getIdTokenResult();
    let method = null;
    if (decodedToken?.claims?.stripeRole) {
      method = 'web';
      redirectToCustomerPortal();
    } else if (decodedToken?.claims?.revenueCatEntitlements?.[0]) {
      method = decodedToken?.claims?.revenueCatEntitlements?.[0].includes(
        'android'
      )
        ? 'Android'
        : 'iOS';

      setIsQuestionModalVisible(true);
      const message = `You purchased this membership through our ${method} app using ${
        method == 'Android' ? 'Google Play Store' : 'Apple Store'
      }. Please log in to our ${method} app to manage your membership, where you can view, upgrade, or cancel it.`;
      setApiErrorMessage(message);
      setIsManageSubscriptionLoading(false);
    }
  };

  const redirectToCustomerPortal = async () => {
    try {
      // Call the callable function from the Firebase extension

      const createPortalLink = httpsCallable(
        functions,
        'ext-firestore-stripe-payments-createPortalLink'
      );

      const result = await createPortalLink({
        returnUrl: `${environment.baseUrl}profile/3`, // URL to return after the user closes the portal
      });

      const { url }: any = result.data;
      setIsManageSubscriptionLoading(false);
      // Redirect the user to the customer portal
      window.location.assign(url);
    } catch (error) {
      setIsManageSubscriptionLoading(false);
      RemoteErrorLog(error, 'TabProfile.tsx');
    }
  };

  const handleChange = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    playBubble();
    setValue(newValue);
    setTabIndex(newValue);
    //needed for leaderboard
    // if (newValue === 3) {
    //   if (!authen?.currentUser?.isAnonymous) {
    //     setIsLoading(true);
    //     const data = await fetchLeaderBoardData();
    //     setIsLoading(false);
    //   }
    // }
  };

  //used in leaderboard section
  // const handleSelectionChange = (option: string) => {
  //   setSelectedOption(option);
  // };

  const hideNoQuestionModal = () => {
    setIsQuestionModalVisible(false);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          allowScrollButtonsMobile
          scrollButtons
          className="tab-starting"
        >
          <Tab className="tab-1" label="Levels" />
          <Tab className="tab-2" label="Badges " />
          <Tab className="tab-3" label="Avatar" />
          {/* <Tab className="tab-4" label="Leaderboard" /> */}
          <Tab className="tab-5" label="Membership" />
        </Tabs>
      </Box>

      <Box className="tab-container scrolling-profile-content level-container">
        <>
          {' '}
          <TabPanel value={value} index={0}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: '18px', sm: '15px' },
                    fontFamily: 'poppins',
                  }}
                  variant="body2"
                  color="#333333"
                  gutterBottom
                >
                  Total Points Scored:
                  <b>
                    {' '}
                    {authen?.currentUser?.isAnonymous
                      ? '--'
                      : profile?.user?.metrics?.points_scored_total}
                  </b>
                </Typography>
                <Typography
                  sx={{
                    fontSize: { md: '18px', sm: '15px' },
                    fontFamily: 'poppins',
                  }}
                  variant="body2"
                  color="#333333"
                  gutterBottom
                >
                  Level acquired:{' '}
                  {authen?.currentUser?.isAnonymous
                    ? '--'
                    : profile?.user?.metrics?.levels_acquired.length}
                  /
                  {authen?.currentUser?.isAnonymous
                    ? '--'
                    : Object.keys(levelPoints).length}
                </Typography>
              </Box>
              <Box className="tab-inside-content">
                {levelPoints &&
                  Object.entries(levelPoints)?.map(
                    ([points, level]: any, index) => {
                      const isAcquired =
                        profile?.user?.metrics?.levels_acquired.includes(level);
                      // Assuming levelsImageUrl is an array where each element corresponds to the level
                      // You might need to adjust this logic depending on how levelsImageUrl is structured
                      const imageUrl = levelsImageUrl[index] || ''; // Default image path if no URL is found

                      return (
                        <Box key={index}>
                          {isAcquired && !authen?.currentUser?.isAnonymous ? (
                            <Box className="tab-flex-text">
                              <img src={imageUrl} alt="tab-img" />
                              <Box>
                                <Typography
                                  className="tab-text-container"
                                  sx={{
                                    fontSize: '16px',
                                    fontFamily: 'poppins',
                                    fontWeight: '500',
                                  }}
                                  variant="body2"
                                  color="#333333"
                                  gutterBottom
                                >
                                  {level}
                                </Typography>
                                {index !== 0 && (
                                  <Typography
                                    className="tab-text-container"
                                    sx={{
                                      fontSize: '16px',
                                      fontFamily: 'poppins',
                                      fontWeight: '500',
                                    }}
                                    variant="body2"
                                    color="#333333"
                                    gutterBottom
                                  >
                                    {points} Points
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          ) : (
                            <Box className="tab-flex-text tab-lock-text">
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '7px',
                                }}
                              >
                                <img src={imageUrl} alt="tab-img" />

                                <Box>
                                  <Typography
                                    className="tab-text-container"
                                    sx={{
                                      fontSize: '16px',
                                      fontFamily: 'poppins',
                                      fontWeight: '500',
                                    }}
                                    variant="body2"
                                    color="#333333"
                                    gutterBottom
                                  >
                                    {level}
                                  </Typography>
                                  {index !== 0 && (
                                    <Typography
                                      className="tab-text-container"
                                      sx={{
                                        fontSize: '16px',
                                        fontFamily: 'poppins',
                                        fontWeight: '500',
                                      }}
                                      variant="body2"
                                      color="#333333"
                                      gutterBottom
                                    >
                                      {points} Points
                                    </Typography>
                                  )}
                                </Box>
                              </Box>
                              <LockIcon
                                sx={{ fontSize: '40px', color: '#FF69B4' }}
                              />
                            </Box>
                          )}
                        </Box>
                      );
                    }
                  )}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography
                  sx={{
                    fontSize: { md: '18px', sm: '15px' },
                    fontFamily: 'poppins',
                  }}
                  variant="body2"
                  color="#333333"
                  gutterBottom
                >
                  Words Mastered:
                  <b>
                    {' '}
                    {authen?.currentUser?.isAnonymous
                      ? '--'
                      : profile?.user?.metrics?.words_num_correct}
                  </b>
                </Typography>
                <Typography
                  sx={{
                    fontSize: { md: '18px', sm: '15px' },
                    fontFamily: 'poppins',
                  }}
                  variant="body2"
                  color="#333333"
                  gutterBottom
                >
                  Badges attained:{' '}
                  {authen?.currentUser?.isAnonymous
                    ? '--'
                    : profile?.user?.metrics?.badges_attained?.length ===
                      undefined
                    ? 0
                    : profile?.user?.metrics?.badges_attained.length}
                  /
                  {authen?.currentUser?.isAnonymous
                    ? '--'
                    : Object.keys(levelPoints).length}
                </Typography>
              </Box>
              <Box className="grid-badges">
                {badgesUrl.detail &&
                  Object.entries(badgesUrl.detail)?.map(
                    ([key, url]: any, index) => {
                      const isBadgeAcquired = profile?.user?.metrics
                        ?.badges_attained
                        ? profile?.user?.metrics?.badges_attained.includes(
                            `${
                              key.split('_').pop().split('.png')[0]
                            } Words Mastered`
                          )
                        : false;
                      return (
                        <Box key={index}>
                          {isBadgeAcquired &&
                            !authen?.currentUser?.isAnonymous && (
                              <Box className="badges-name">
                                <img
                                  src={url}
                                  alt="badges"
                                  style={{ width: '78%' }}
                                />
                                <Typography
                                  className="tab-text-container"
                                  sx={{
                                    fontFamily: 'poppins',
                                    fontWeight: '500',
                                    textAlign: 'center',
                                  }}
                                  paddingTop="15px"
                                  variant="body2"
                                  color="#333333"
                                  gutterBottom
                                >
                                  {`${key.split('_').pop().split('.png')[0]}`}{' '}
                                  Words Mastered
                                </Typography>
                              </Box>
                            )}
                          {!isBadgeAcquired &&
                            !authen?.currentUser?.isAnonymous && (
                              <Box
                                className="badges-name"
                                sx={{ position: 'relative' }}
                              >
                                <img
                                  src={url}
                                  alt="badges"
                                  style={{ width: '78%' }}
                                />
                                <Typography
                                  className="tab-text-container"
                                  sx={{
                                    fontFamily: 'poppins',
                                    fontWeight: '500',
                                    textAlign: 'center',
                                  }}
                                  paddingTop="15px"
                                  variant="body2"
                                  color="#333333"
                                  gutterBottom
                                >
                                  {`${key.split('_').pop().split('.png')[0]}`}{' '}
                                  Words Mastered
                                </Typography>

                                <LockIcon
                                  sx={{ fontSize: '40px', color: '#FF69B4' }}
                                  className="lock-badges"
                                />
                              </Box>
                            )}
                          {authen?.currentUser?.isAnonymous && (
                            <Box
                              className="badges-name"
                              sx={{ position: 'relative' }}
                            >
                              <img
                                src={url}
                                alt="badges"
                                style={{ width: '78%' }}
                              />
                              <Typography
                                className="tab-text-container"
                                sx={{
                                  fontFamily: 'poppins',
                                  fontWeight: '500',
                                  textAlign: 'center',
                                }}
                                variant="body2"
                                color="#333333"
                                gutterBottom
                              >
                                {`${key.split('_').pop().split('.png')[0]}`}{' '}
                                Words Mastered
                              </Typography>

                              <LockIcon
                                sx={{ fontSize: '40px', color: '#FF69B4' }}
                                className="lock-badges"
                              />
                            </Box>
                          )}
                        </Box>
                      );
                    }
                  )}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Box>
              <Box sx={{ textAlign: 'center', paddingTop: '5px' }}>
                {/* <img src={AvatarPic1} alt="avatarpic" style={{ width: '20%' }} /> */}
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontFamily: 'poppins',
                    fontWeight: '500',
                  }}
                  variant="body2"
                  color="#333333"
                  gutterBottom
                >
                  Choose your preferred avatar by tapping the pencil icon on
                  your profile picture.
                </Typography>
              </Box>

              {!authen?.currentUser?.isAnonymous && (
                <Box className="avatar-image-grid">
                  {imageTab.map((url: any, index: number) => (
                    <img
                      src={url}
                      key={index}
                      alt="avatar-img-2"
                      style={{ width: '50%' }}
                    />
                  ))}
                </Box>
              )}
              {authen?.currentUser?.isAnonymous && (
                <Box className=" avatar-image-grid">
                  {imageTab.map((url: any, index: number) => (
                    <Box className="lock-avatar  ">
                      <>
                        <img
                          src={url}
                          key={index}
                          alt="avatar-img-2"
                          style={{ width: '90px' }}
                        />
                        <LockIcon
                          className="lock-icon"
                          sx={{ fontSize: '40px', color: '#FF69B4' }}
                        />
                      </>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </TabPanel>
          {/* leaderboard */}
          {/* <TabPanel value={value} index={3}>
            {isLoading && !authen?.currentUser?.isAnonymous ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100vh',
                }}
              >
                <CircularProgress
                  size={40}
                  sx={{
                    color: 'primary.main',
                  }}
                />
              </Box>
            ) : (
              <Box>
                <Box>
                  <Leaderboardradiobtn
                    handleSelectionChange={handleSelectionChange}
                    selectedValue={selectedOption}
                  />
                </Box>
                <Box className="Green-leaderboard-box">
                  <Box sx={{ position: 'relative' }}>
                    <Box className="green-boxes">
                      <Box className="circle-inside-box">
                        <img
                          src={Leaderboardpic_one}
                          alt="pic-1"
                          style={{ width: '40%' }}
                        />
                        <Typography
                          className="player-1"
                          sx={{
                            fontFamily: 'poppins',
                            fontWeight: 400,
                            paddingTop: '12px',
                          }}
                          variant="body2"
                          color="#FFFFFF"
                          gutterBottom
                        >
                          {!authen?.currentUser?.isAnonymous
                            ? selectedOption === '7days' &&
                              sevenDayPlayers !== null
                              ? sevenDayPlayers[1]?.screen_name?.slice(0, 14)
                              : fourteenDayPlayers !== null
                              ? fourteenDayPlayers[1]?.screen_name?.slice(0, 14)
                              : '--'
                            : '--'}
                        </Typography>
                        <Typography
                          className="player-1-subtitle"
                          sx={{
                            fontFamily: 'poppins',
                          }}
                          variant="body2"
                          color="#FFF"
                          gutterBottom
                        >
                          {!authen?.currentUser?.isAnonymous
                            ? selectedOption === '7days' &&
                              sevenDayPlayers !== null
                              ? sevenDayPlayers[1]?.points_scored
                              : fourteenDayPlayers !== null
                              ? fourteenDayPlayers[1]?.points_scored
                              : '--'
                            : '--'}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="players-score">
                      {' '}
                      {!authen?.currentUser?.isAnonymous
                        ? selectedOption === '7days' && sevenDayPlayers !== null
                          ? sevenDayPlayers[1]?.rank
                          : fourteenDayPlayers !== null
                          ? fourteenDayPlayers[1]?.rank
                          : '--'
                        : '--'}
                    </Box>
                  </Box>

                  <Box sx={{ position: 'relative' }}>
                    <Box className="green-boxes">
                      <Box className="circle-inside-box-topper">
                        <img
                          src={Leaderboardpic_one}
                          alt="pic-1"
                          style={{ width: '43%' }}
                        />
                        <Typography
                          className="player-1"
                          sx={{
                            fontFamily: 'poppins',
                            fontWeight: 400,
                            paddingTop: '12px',
                          }}
                          variant="body2"
                          color="#FFFFFF"
                          gutterBottom
                        >
                          {!authen?.currentUser?.isAnonymous
                            ? selectedOption === '7days' &&
                              sevenDayPlayers !== null
                              ? sevenDayPlayers[2]?.screen_name?.slice(0, 14)
                              : fourteenDayPlayers !== null
                              ? fourteenDayPlayers[2]?.screen_name?.slice(0, 14)
                              : '--'
                            : '--'}
                        </Typography>
                        <Typography
                          className="player-2-subtitle"
                          sx={{
                            fontFamily: 'poppins',
                          }}
                          variant="body2"
                          color="#FFF"
                          gutterBottom
                        >
                          {!authen?.currentUser?.isAnonymous
                            ? selectedOption === '7days' &&
                              sevenDayPlayers !== null
                              ? sevenDayPlayers[2]?.points_scored
                              : fourteenDayPlayers !== null
                              ? fourteenDayPlayers[2]?.points_scored
                              : '--'
                            : '--'}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="players-score-topper">
                      {!authen?.currentUser?.isAnonymous
                        ? selectedOption === '7days' && sevenDayPlayers !== null
                          ? sevenDayPlayers[2]?.rank
                          : fourteenDayPlayers !== null
                          ? fourteenDayPlayers[2]?.rank
                          : '--'
                        : '--'}
                    </Box>
                  </Box>
                  <Box sx={{ position: 'relative' }}>
                    <Box className="green-boxes">
                      <Box className="circle-inside-box">
                        <img
                          src={Leaderboardpic_one}
                          alt="pic-1"
                          style={{ width: '40%' }}
                        />
                        <Typography
                          className="player-3"
                          sx={{
                            fontFamily: 'poppins',
                            fontWeight: 400,
                            paddingTop: '12px',
                            fontSize: { sm: '12px', md: '16px' },
                          }}
                          variant="body2"
                          color="#FFFFFF"
                          gutterBottom
                        >
                          {!authen?.currentUser?.isAnonymous
                            ? selectedOption === '7days' &&
                              sevenDayPlayers !== null
                              ? sevenDayPlayers[3]?.screen_name?.slice(0, 14)
                              : fourteenDayPlayers !== null
                              ? fourteenDayPlayers[3]?.screen_name?.slice(0, 14)
                              : '--'
                            : '--'}
                        </Typography>
                        <Typography
                          className="player-3-subtitle"
                          sx={{
                            fontFamily: 'poppins',
                          }}
                          variant="body2"
                          color="#FFF"
                          gutterBottom
                        >
                          {!authen?.currentUser?.isAnonymous
                            ? selectedOption === '7days' &&
                              sevenDayPlayers !== null
                              ? sevenDayPlayers[3]?.points_scored
                              : fourteenDayPlayers !== null
                              ? fourteenDayPlayers[3]?.points_scored
                              : '--'
                            : '--'}
                        </Typography>
                      </Box>
                    </Box>

                    <Box className="players-score">
                      {' '}
                      {!authen?.currentUser?.isAnonymous
                        ? selectedOption === '7days' && sevenDayPlayers !== null
                          ? sevenDayPlayers[3]?.rank
                          : fourteenDayPlayers !== null
                          ? fourteenDayPlayers[3]?.rank
                          : '--'
                        : '--'}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
                >
                  <Box className="leaderboard-flex-parent">
                    <Box className="leaderboard-flex-child">
                      <img src={Leaderboardimg2} alt="pic-2" width="30%" />
                      <Typography
                        className="m-0"
                        sx={{
                          fontSize: '20px',
                          fontFamily: 'poppins',
                          fontWeight: '400',
                        }}
                        variant="body2"
                        color="#333333"
                        gutterBottom
                      >
                        {!authen?.currentUser?.isAnonymous
                          ? selectedOption === '7days' &&
                            sevenDayPlayers !== null
                            ? sevenDayPlayers[4]?.screen_name?.slice(0, 14)
                            : fourteenDayPlayers !== null
                            ? fourteenDayPlayers[4]?.screen_name?.slice(0, 14)
                            : '--'
                          : '--'}
                      </Typography>
                    </Box>

                    <Typography
                      sx={{
                        fontSize: '24px',
                        fontFamily: 'poppins',
                        fontWeight: '600',
                      }}
                      variant="body2"
                      color="#333333"
                      gutterBottom
                    >
                      {!authen?.currentUser?.isAnonymous
                        ? selectedOption === '7days' && sevenDayPlayers !== null
                          ? sevenDayPlayers[4]?.points_scored
                          : fourteenDayPlayers !== null
                          ? fourteenDayPlayers[4]?.points_scored
                          : '--'
                        : '--'}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '24px',
                        fontFamily: 'poppins',
                        fontWeight: '600',
                      }}
                      variant="body2"
                      color="#333333"
                      gutterBottom
                    >
                      {!authen?.currentUser?.isAnonymous
                        ? selectedOption === '7days' && sevenDayPlayers !== null
                          ? sevenDayPlayers[3]?.rank
                          : fourteenDayPlayers !== null
                          ? fourteenDayPlayers[3]?.rank
                          : '--'
                        : '--'}
                    </Typography>
                  </Box>
                
                </Box>
              </Box>
            )}
          </TabPanel> */}
          {/* membership */}
          <TabPanel value={value} index={3}>
            {isPremium == false &&
              authen?.currentUser?.isAnonymous == false && (
                <Box className="membership-profile">
                  <Box className="membership-ad">
                    <Box>
                      <Typography
                        className="membership-current"
                        variant="body2"
                        color="#333333"
                        fontFamily="inter"
                        fontWeight="600"
                        gutterBottom
                      >
                        My subscription
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '17px',
                          fontWeight: '500',
                          fontFamily: 'inter',
                        }}
                        variant="body2"
                        color="#333333"
                        gutterBottom
                      >
                        Ad-Supported
                      </Typography>
                    </Box>
                    <img src={SpellingLogo} alt="logo-spelling" />
                  </Box>
                  <Divider
                    sx={{
                      marginTop: '20px',
                      borderWidth: '1px',
                      marginBottom: '20px',
                    }}
                  />

                  <Box>
                    <Typography
                      className="benefit-ad"
                      variant="body2"
                      color="#333333"
                      fontFamily="inter"
                      fontWeight="600"
                      // marginTop="30px"
                      gutterBottom
                    >
                      Benefits of Ad-Free membership
                    </Typography>
                    <ul className="list-membership">
                      <li>
                        Ad-Free Experience: Enjoy uninterrupted usage without
                        any ads.
                      </li>
                      <li>
                        Full Functionality: Access all features and capabilities
                        as before.
                      </li>
                      <li>
                        Upgrade Today: Make the switch to the paid membership
                        for an enhanced experience.
                      </li>
                    </ul>
                    <Box>
                      <Typography
                        fontSize="24px"
                        variant="body2"
                        color="#23A6F0"
                        fontFamily="inter"
                        fontWeight="700"
                        marginTop="16px"
                        gutterBottom
                      >
                        <span className="cursor-pointer">
                          Upgrade membership
                        </span>
                      </Typography>

                      <Box className="tab-profile-game member-profile-payment">
                        <MembershipTab />
                      </Box>

                      {/* <div> */}
                      {/* <stripe-buy-button
                      buy-button-id="buy_btn_1PmrW2LUPLIOScuQ4QxrqEGp"
                      customer-email="vyom@yop.com"
                      client_reference_id={'12321321'}
                      publishable-key="pk_test_51NeMKILUPLIOScuQxCHbwwK7vYeFPqdyhfFxaPsQsENsv6DUA8JpudUPP6BFp785CDoppckJrf9ASZQsUVdQL5TG00F4elkxzw"
                    ></stripe-buy-button> */}
                      {/* <stripe-pricing-table
                      pricing-table-id="prctbl_1PV0PmLUPLIOScuQ4G5rCRsw"
                      publishable-key="pk_test_51NeMKILUPLIOScuQxCHbwwK7vYeFPqdyhfFxaPsQsENsv6DUA8JpudUPP6BFp785CDoppckJrf9ASZQsUVdQL5TG00F4elkxzw"
                      client-reference-id={authen?.currentUser?.uid}
                      customer-email={authen?.currentUser?.email}
                    ></stripe-pricing-table> */}
                      {/* </div> */}

                      {/* {isPremium == false &&
                    product?.map((price: any) => (
                      <button
                        onClick={() =>
                          authen?.currentUser?.uid &&
                          createCheckoutSession(
                            authen?.currentUser?.uid,
                            price?.priceId
                          )
                        }
                      >
                        {price?.product?.name} - $
                        {price?.price?.unit_amount / 100}{' '}
                        {price?.price?.currency.toUpperCase()} (
                        {price?.price?.recurring?.interval_count})(
                        {price?.price?.recurring?.interval}) Upgrade to premium!
                      </button>
                    ))} */}

                      {/* {isPremium == true && (
                    <button onClick={redirectToCustomerPortal}>
                      Manage Subscription
                    </button>
                  )} */}
                    </Box>
                  </Box>
                </Box>
              )}
            {authen?.currentUser?.isAnonymous == true && (
              <Box className="membership-profile">
                <Typography
                  // className="membership-current"
                  // variant="body2"
                  // color="#333333"
                  // fontFamily="inter"
                  fontWeight="500"
                  // gutterBottom
                >
                  Sign up to explore Ad-Free membership upgrade options!
                </Typography>
              </Box>
            )}
            {/* second part */}

            {isPremium == true && (
              <Box className="membership-profile">
                <Box className="membership-ad">
                  <Box>
                    <Typography
                      className="membership-current"
                      variant="body2"
                      color="#333333"
                      fontFamily="inter"
                      fontWeight="600"
                      gutterBottom
                    >
                      My subscription
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '17px',
                        fontWeight: '500',
                        fontFamily: 'inter',
                      }}
                      variant="body2"
                      color="#333333"
                      gutterBottom
                    >
                      Ad-Free {subscription}
                    </Typography>
                  </Box>
                  <img
                    src={SpellingLogo}
                    alt="logo-spelling"
                    className="logo-spelling"
                  />
                </Box>

                <Box>
                  {/* <Typography
                    sx={{
                      fontSize: '17px',
                      fontWeight: '500',
                      fontFamily: 'inter',
                    }}
                    variant="body2"
                    color="#333333"
                    gutterBottom
                  >
                    Ad Free {subscription}
                  </Typography> */}
                  <Typography
                    sx={{
                      fontSize: '20px',
                      // fontWeight: '700',
                      fontFamily: 'inter',
                    }}
                    variant="body2"
                    color="#333333"
                    gutterBottom
                  >
                    View invoices, update subscription & payment methods
                  </Typography>
                </Box>

                <Divider
                  sx={{
                    marginTop: '8px',
                    marginBottom: '12px',
                    borderWidth: '1px',
                  }}
                />
                {isManageSubscriptionLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      // height: '100vh',
                    }}
                  >
                    <CircularProgress
                      size={40}
                      sx={{
                        color: 'primary.main',
                      }}
                    />
                  </Box>
                ) : (
                  <button
                    className="btn btn-primary manage-btn-member"
                    onClick={manageMembership}
                  >
                    Manage membership
                  </button>
                )}
              </Box>
            )}
          </TabPanel>
        </>
        <CustomNoResponseModal
          isModalVisible={isQuestionModalVisible}
          onPress={hideNoQuestionModal}
          buttonText="OK"
          modalTitle="Manage Subscription"
          text={apiErrorMessage}
          color="success"
          icon={false}
        />
      </Box>
    </Box>
  );
}
