import { FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authen } from '../../firebase/firebase';
import { signInWithCustomToken } from 'firebase/auth';

const CleverCallback: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      const token = searchParams.get('token');
      const userId = searchParams.get('user_id');

      if (token && userId) {
        try {
          const credential = await signInWithCustomToken(authen, token);
          localStorage.setItem('userData', JSON.stringify(credential.user));
          navigate('/home');
        } catch (error) {
          console.error('Authentication error:', error);
          navigate('/');
        }
      } else {
        navigate('/');
      }
    };

    handleAuth();
  }, [searchParams, navigate]);

  return <div></div>;
};

export default CleverCallback;