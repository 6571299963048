export const environment = {
    production: true,
    environmentName: 'development',
    baseUrl:'https://dev.spellinghero.app/',
    backendUrl:'https://custom-auth-610140353113.northamerica-northeast1.run.app/',
    apiUrl:'https://ui-interaction-2pkagc3btq-nn.a.run.app/',
    region:'northamerica-northeast1',
    recaptchaSiteKey:"6LePWCAqAAAAAHmd-kWC7P4POo6EgGKBu7TZWh1Q",
    stripePublishableKey:"pk_test_51NeMKILUPLIOScuQxCHbwwK7vYeFPqdyhfFxaPsQsENsv6DUA8JpudUPP6BFp785CDoppckJrf9ASZQsUVdQL5TG00F4elkxzw",
    firebase: {
        apiKey: "AIzaSyDuoT-lBjL69kpIb0Q27pl2jRqneVmJK3E",
        authDomain: "spelling-app-d.firebaseapp.com",
        projectId: "spelling-app-d",
        storageBucket: "spelling-app-d.appspot.com",
        messagingSenderId: "610140353113",
        appId: "1:610140353113:web:b5645adf819a869e16f748",
        measurementId: "G-5H974DZXPN"
      // ... other Firebase config
    }
  };