import React, { useEffect, useMemo, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Header from '../../components/Header/Header';
import googleads980 from '../../../assets/img/google-ads-930.jpg';
import googleads300 from '../../../assets/img/google-ads-300.png';
import '../../pages/Profile/Profile.scss';
import Footer from '../../components/Footer/Footer';
import '../../scss/Quiz.scss';
import '../Profile/Profile.scss';
import TablerEdit from '../../../assets/img/tabler_edit.svg';
import Levelgraph from '../../../assets/img/Levelgraph.png';
import Score from '../../../assets/img/score.svg';
import Memebership from '../../../assets/img/Member-star.png';
import TabProfile from '../../components/TabProfile/TabProfile';
import { authen, db } from '../../firebase/firebase';
import { setUserProfile } from 'shared/store/src/lib/actions/userprofile';
import { doc, getDoc } from '@firebase/firestore';
import RemoteErrorLog from '../../Utils/api';
import { Button, Container, Grid, Box, Paper, Typography } from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation, Location } from 'react-router-dom';

interface LocationState {
  openMembershipTab?: boolean;
  tabIndex?: number;
}

const Profile = () => {
  const [user, setUser] = useState<any>();
  const profile = useSelector((state: any) => state.userprofile);
  const [tabIndex, setTabIndex] = React.useState(0);
  const dispatch = useDispatch();
  const location = useLocation() as Location & {
    state: LocationState | null;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (user?.uid) {
      getUserProfile(user?.uid);
    } else if (authen?.currentUser?.uid) {
      getUserProfile(authen?.currentUser?.uid);
    }
  }, [user, authen]);

  useEffect(() => {
    if (location.state?.openMembershipTab) {
      setTabIndex(location.state.tabIndex || 3);
    }
  }, [location.state]);


  const getUserProfile = async (uid: string) => {
    try {
      const docRef = doc(db, 'user_profile', uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        dispatch(setUserProfile(data));
        return data;
      } else {
        return null;
      }
    } catch (error) {
      RemoteErrorLog(error, 'MyProgress.tsx');

      return null;
    }
  };

  return (
    <Box>
      <header>
        <Navbar currentPage="myprofile" />
      </header>
      <Header />
      <main className="page-bg overflow-hidden">
        <Container
          component="section"
          sx={{
            maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
          }}
        >
          {/* <Box className="googleAds top mx-auto pb-4">
            <img src={googleads980} alt="Google Ads" />
          </Box> */}
          <section>
            <Grid container spacing={3}>
              <Grid item lg={3} xs={12} sx={{ textAlign: 'center' }}>
                {/* <Box className="googleAds top mx-auto">
                <img src={googleads300} alt="Google Ads" />
              </Box> */}
              </Grid>
              <Grid item lg={6} xs={12}>
                <section className="quiz-step profile-step ">
                  <Typography variant="h3">My Profile</Typography>
                  <Paper
                    className="box-container"
                    sx={{ padding: '14px 20px !important', boxShadow: 'none' }}
                  >
                    <Box className="parent">
                      <Box className="box1" component="section">
                        <img src={profile?.user?.img_uri_https} alt="Profile" />
                        <Typography
                          variant="h5"
                          component="h2"
                          fontSize="18px"
                          fontWeight="500"
                          textAlign="center"
                        >
                          {profile?.user?.full_name}
                        </Typography>
                        <Button
                          className="btn btn-primary"
                          sx={{ textTransform: 'none' }}
                          component={Link}
                          to="/profile/edit"
                        >
                          <span>
                            <img src={TablerEdit} alt="Edit" />
                            <span
                              style={{ marginLeft: '5px' }}
                              className="edit-icon"
                            >
                              Edit profile
                            </span>
                          </span>
                        </Button>
                      </Box>
                      <Box className="box2" component="section">
                        <Box className="profile-box-2">
                          <img src={Levelgraph} alt="Level Graph" />
                          <Typography
                            variant="h6"
                            className="font-15"
                            sx={{ lineHeight: '1.2' }}
                          >
                            Level
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          className={`text-2-profile font-16`}
                        >
                          {authen?.currentUser?.isAnonymous
                            ? '--'
                            : profile.user?.metrics?.name_level_curr}
                        </Typography>

                        <Box className="border-light-blue-profile"></Box>
                      </Box>
                      <Box className="box3" component="section">
                        <Box component={Link} to="/score">
                          <Box
                            className={` profile-box-2 ${
                              authen?.currentUser?.isAnonymous ? '' : ''
                            }`}
                          >
                            <img src={Memebership} alt="Score Graph" />
                            <Typography variant="h6" className="font-15">
                              Score
                            </Typography>
                          </Box>
                          <Typography
                            variant="h6"
                            className="text-2-profile font-16"
                          >
                            {authen?.currentUser?.isAnonymous
                              ? '--'
                              : profile.user?.metrics?.words_num_correct}
                            /
                            {authen?.currentUser?.isAnonymous
                              ? '--'
                              : profile.user?.metrics?.words_num_total}
                          </Typography>
                          <Box className="border-light-skin-color-profile"></Box>
                        </Box>
                      </Box>
                      <Box
                        className="box4 cursor-pointer"
                        component="section"
                        onClick={() => setTabIndex(3)}
                      >
                        <Box
                          className={` profile-box-2 ${
                            authen?.currentUser?.isAnonymous ? '' : ''
                          }`}
                        >
                          <img src={Score} alt="memeber Graph" />
                          <Typography variant="h6" className="font-15">
                            Membership
                          </Typography>
                        </Box>
                        <Typography
                          variant="h6"
                          className="text-2-profile font-16"
                        >
                          Remove Ads
                        </Typography>
                        <Box className="border-light-voilet-color-profile"></Box>
                      </Box>
                      <Box className="box5" component="section">
                        <Box
                          className="current-level result-current-level"
                          sx={{ borderRadius: '12px' }}
                        >
                          <Box>
                            <Typography
                              variant="h6"
                              className="font-500 current-level-word"
                              sx={{
                                fontFamily: 'roboto',
                                color: '#333333',
                              }}
                            >
                              Current Level:{' '}
                              <Typography
                                component={'span'}
                                className="font-500 current-level-word"
                                style={{ fontStyle: 'italic' }}
                                sx={{
                                  fontFamily: 'roboto',
                                  color: '#333333',
                                }}
                              >
                                {' '}
                                {!authen.currentUser?.isAnonymous
                                  ? profile.user?.metrics?.name_level_curr
                                  : '--'}{' '}
                              </Typography>
                            </Typography>
                            <Box className="slider-current-level">
                              <Box
                                className="customprogress "
                                sx={{ width: 'unset', height: '15px' }}
                              >
                                {!authen.currentUser?.isAnonymous && (
                                  <Typography className="custmPrgsTxtunfill profiletextfill">
                                    {(profile.user?.metrics
                                      ?.next_level_points_scored /
                                      profile.user?.metrics
                                        ?.next_level_points_total) *
                                      100 <=
                                      70 &&
                                      `${profile.user?.metrics?.next_level_points_total} Points`}
                                  </Typography>
                                )}

                                <Box
                                  className="innerFill profilefill"
                                  sx={{
                                    width: !authen.currentUser?.isAnonymous
                                      ? `${
                                          (profile.user?.metrics
                                            ?.next_level_points_scored /
                                            profile.user?.metrics
                                              ?.next_level_points_total) *
                                            100 <=
                                          3
                                            ? 3
                                            : (profile.user?.metrics
                                                ?.next_level_points_scored /
                                                profile.user?.metrics
                                                  ?.next_level_points_total) *
                                              100
                                        }%`
                                      : '3%',
                                  }}
                                >
                                  {!authen.currentUser?.isAnonymous && (
                                    <Typography className="custmPrgsTxt">
                                      {(profile.user?.metrics
                                        ?.next_level_points_scored /
                                        profile.user?.metrics
                                          ?.next_level_points_total) *
                                        100 >=
                                        22 &&
                                        `${profile.user?.metrics?.next_level_points_scored} Points`}
                                    </Typography>
                                  )}
                                  <Box className="customtooltip">
                                    {`${
                                      authen.currentUser?.isAnonymous
                                        ? '--'
                                        : profile.user?.metrics
                                            ?.next_level_points_scored
                                    } `}
                                  </Box>
                                </Box>
                              </Box>
                            </Box>

                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: 'roboto',
                                color: '#333333',
                                fontSize: '16px',
                                fontWeight: '400',
                              }}
                              className="current-level-para"
                            >
                              You need just{' '}
                              {!authen.currentUser?.isAnonymous
                                ? profile.user?.metrics
                                    ?.next_level_points_remaining
                                : '--'}{' '}
                              more points to reach the next level!
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </section>
                <section className="tab-profile-game mt-4">
                  <TabProfile tabIndex={tabIndex} setTabIndex={setTabIndex} />
                </section>
              </Grid>
              <Grid
                item
                lg={3}
                xs={12}
                className="pb-3"
                sx={{ textAlign: 'center' }}
              >
                {/* <Box className="googleAds top mx-auto">
                <img src={googleads300} alt="Google Ads" />
              </Box> */}
              </Grid>
            </Grid>
          </section>
        </Container>
      </main>

      <Footer />
    </Box>
  );
};

export default Profile;
