interface UserProfileData {
    auth_type?: string;
    channel?: string;
    free_trial_expiry?: string | number;
    metrics?: {
      words_num_total?: number;
      [key: string]: any;
    };
    [key: string]: any;
  }
  
  interface TrialExpiryResult {
    expired: boolean;
    message: string;
    redirectToMembership: boolean;
  }
  
  export const checkTrialExpiry = (userData: UserProfileData | null): TrialExpiryResult => {
    const response: TrialExpiryResult = {
      expired: false,
      message: "",
      redirectToMembership: false
    };
  
    if (!userData) return response;
  
    const dateCheckAuthTypes: string[] = [
      'classlink-roster', 
      'classlink-sso', 
      'clever-library', 
      'district-sso', 
      'indian-school-plan'
    ];
  
    const isDateBasedValidation = userData.auth_type && dateCheckAuthTypes.includes(userData.auth_type);
  
    if (isDateBasedValidation) {
      if (userData.free_trial_expiry) {
        const currentDate = new Date();
        const expiryDate = new Date(userData.free_trial_expiry);
        
        if (currentDate > expiryDate) {
          return {
            expired: true,
            message: "Your free trial has expired. Please contact your school or teacher to purchase a subscription for you.",
            redirectToMembership: true
          };
        }
      }
    } else if (userData.channel === 'website') {
      if (!userData.free_trial_expiry && userData.metrics?.words_num_total && userData.metrics.words_num_total > 50) {
        return {
          expired: true,
          message: "You have exceeded the maximum number of free tests in your trial. Please ask your parents to upgrade your membership.",
          redirectToMembership: true
        };
      }
    }
  
    return response;
  };