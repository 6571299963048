import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Container,
  CircularProgress,
  Autocomplete,
  DialogContent,
  DialogActions,
  Dialog,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import DocumentViewerModal from '../../components/DocumentViewerModal';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { storage } from '../../firebase/firebase';
import RemoteErrorLog from '../../Utils/api';
import { environment } from '../../../environments/environment';
import CustomModal from '../../components/CustomModal';

interface SchoolFormData {
  schoolName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  adminEmail: string;
  adminPhone: string;
  adminUsername: string;
  password: string;
  confirmPassword: string;
  termsAgreed: boolean;
}

interface DocumentUrls {
  privacyPolicy: string | null;
  termsAndConditions: string | null;
}

export default function SchoolRegistration() {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState<boolean>(false);
  const [termsOpen, setTermsOpen] = useState<boolean>(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState<boolean>(false);
  const [registeredSchoolId, setRegisteredSchoolId] = useState<string>('');
  const [schoolIdCopied, setSchoolIdCopied] = useState(false);
  const [loginLinkCopied, setLoginLinkCopied] = useState(false);
  const [emailVerificationModalOpen, setEmailVerificationModalOpen] = useState<boolean>(false);
  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [errorModalMessage, setErrorModalMessage] = useState<string>('');
  const [documentUrls, setDocumentUrls] = useState<DocumentUrls>({
    privacyPolicy: null,
    termsAndConditions: null,
  });

  const initialFormState: SchoolFormData = {
    schoolName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    adminEmail: '',
    adminPhone: '',
    adminUsername: '',
    password: '',
    confirmPassword: '',
    termsAgreed: false
  };

  const INDIAN_STATES = [
    'Andhra Pradesh',
    'Arunachal Pradesh',
    'Assam',
    'Bihar',
    'Chhattisgarh',
    'Goa',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jharkhand',
    'Karnataka',
    'Kerala',
    'Madhya Pradesh',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Mizoram',
    'Nagaland',
    'Odisha',
    'Punjab',
    'Rajasthan',
    'Sikkim',
    'Tamil Nadu',
    'Telangana',
    'Tripura',
    'Uttar Pradesh',
    'Uttarakhand',
    'West Bengal',
    'Andaman and Nicobar Islands',
    'Chandigarh',
    'Dadra and Nagar Haveli and Daman and Diu',
    'Delhi',
    'Jammu and Kashmir',
    'Ladakh',
    'Lakshadweep',
    'Puducherry'
  ];

  const [formData, setFormData] = useState<SchoolFormData>(initialFormState);

  const resetForm = () => {
    setFormData(initialFormState);
    setIsChecked(false);
    setErrors({});
    formRef.current?.reset();
  };

  const [errors, setErrors] = useState<Partial<Record<keyof SchoolFormData, string>>>({});

  const fetchPages = async () => {
    try {
      const storageRef = ref(storage, 'pages');
      const result = await listAll(storageRef);
      const urls = await Promise.all(
        result.items.map((pages) => getDownloadURL(pages))
      );

      setDocumentUrls({
        termsAndConditions: urls[2] || null,
        privacyPolicy: urls[1] || null,
      });
    } catch (error) {
      RemoteErrorLog(error, 'SchoolRegistration.tsx');
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    setEmailVerificationModalOpen(true);
  };

  const handleCopy = async (text: string, setCopied: (value: boolean) => void) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof SchoolFormData, string>> = {};
    let isValid = true;

    if (!formData.schoolName.trim()) {
      newErrors.schoolName = 'School name is required';
      isValid = false;
    }

    if (!formData.address.trim()) {
      newErrors.address = 'Address is required';
      isValid = false;
    }

    if (!formData.city.trim()) {
      newErrors.city = 'City is required';
      isValid = false;
    }

    if (!formData.state.trim()) {
      newErrors.state = 'State is required';
      isValid = false;
    }

    if (!formData.zipCode.trim()) {
      newErrors.zipCode = 'Zip code is required';
      isValid = false;
    } else if (!/^\d{6}$/.test(formData.zipCode)) {
      newErrors.zipCode = 'Zip code must be 6 digits';
      isValid = false;
    }

    if (!formData.adminEmail.trim()) {
      newErrors.adminEmail = 'Email is required';
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.adminEmail)) {
      newErrors.adminEmail = 'Invalid email format';
      isValid = false;
    }

    if (!formData.adminUsername.trim()) {
      newErrors.adminUsername = 'Username is required';
      isValid = false;
    }

    if (!formData.password) {
      newErrors.password = 'Password is required';
      isValid = false;
    } else if (formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
      isValid = false;
    }

    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
      isValid = false;
    }

    if (!isChecked) {
      newErrors.termsAgreed = 'You must agree to the terms and conditions';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleInputChange = (field: keyof SchoolFormData) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({
      ...formData,
      [field]: event.target.value,
    });

    if (errors[field]) {
      setErrors({
        ...errors,
        [field]: undefined,
      });
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitError(null);

    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${environment.backendUrl}/register-school`,
        {
          schoolName: formData.schoolName,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          zipCode: formData.zipCode,
          adminEmail: formData.adminEmail,
          adminPhone: formData.adminPhone || undefined,
          adminUsername: formData.adminUsername,
          password: formData.password,
        }
      );

      if (response.data.status === 'success') {
        setRegisteredSchoolId(response.data.school_id);
        resetForm();
        setSuccessDialogOpen(true);
      }

    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorData = error.response?.data;
        
        if (errorData?.error === "School with these details already exists" || 
            errorData?.error === "Admin with this email already exists" ||
            (errorData?.error === "Failed to create admin user" && 
             errorData?.details?.includes("EMAIL_EXISTS"))) {
          
          let userMessage = errorData.error;
          if (errorData?.details?.includes("EMAIL_EXISTS")) {
            userMessage = "An account with this email already exists. Please use a different email address.";
          }
          
          setErrorModalMessage(userMessage);
          setErrorModalOpen(true);
        } else {
          setSubmitError(errorData?.error || 'Failed to register school');
        }
        
        RemoteErrorLog(error, 'SchoolRegistration.tsx');
      } else {
        setSubmitError('An unexpected error occurred');
        RemoteErrorLog(error, 'SchoolRegistration.tsx');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePolicyClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (documentUrls.privacyPolicy) {
      setPrivacyPolicyOpen(true);
    }
  };

  const handleTermsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (documentUrls.termsAndConditions) {
      setTermsOpen(true);
    }
  };

  return (
    <>
      <Navbar currentPage="register-school" />
      <Container maxWidth="md" sx={{ py: 4, mt: { xs: 8, sm: 9 }, mb: 4 }}>
        <Paper elevation={3} sx={{ p: { xs: 2, md: 4 }, borderRadius: 2, position: 'relative' }}>
          {isSubmitting && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 5,
                borderRadius: 2
              }}
            >
              <CircularProgress size={60} sx={{ color: 'green', mb: 3 }} />
              <Typography variant="h6" color="textSecondary">
                Registering your school...
              </Typography>
            </Box>
          )}

          <Typography variant="h4" align="center" gutterBottom color="green">
            School Registration
          </Typography>

          <form ref={formRef} onSubmit={handleSubmit}>
            <Typography variant="h6" gutterBottom sx={{
              display: 'block',
              mb: 2,
              mt: 4,
              fontWeight: 500,
              color: 'text.primary',
            }}>
              School Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="School Name"
                  fullWidth
                  required
                  value={formData.schoolName}
                  onChange={handleInputChange('schoolName')}
                  error={!!errors.schoolName}
                  helperText={errors.schoolName}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Address"
                  fullWidth
                  multiline
                  required
                  value={formData.address}
                  onChange={handleInputChange('address')}
                  error={!!errors.address}
                  helperText={errors.address}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="City"
                  fullWidth
                  required
                  value={formData.city}
                  onChange={handleInputChange('city')}
                  error={!!errors.city}
                  helperText={errors.city}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  id="state-select"
                  options={INDIAN_STATES}
                  value={formData.state}
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      state: newValue || '',
                    });
                    if (errors.state) {
                      setErrors({
                        ...errors,
                        state: undefined,
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      required
                      error={!!errors.state}
                      helperText={errors.state}
                    />
                  )}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Zip Code"
                  fullWidth
                  required
                  value={formData.zipCode}
                  onChange={handleInputChange('zipCode')}
                  inputProps={{ maxLength: 6 }}
                  error={!!errors.zipCode}
                  helperText={errors.zipCode}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Typography variant="h6" gutterBottom sx={{
              display: 'block',
              mb: 2,
              mt: 4,
              fontWeight: 500,
              color: 'text.primary',
            }}>
              Admin Contact Information
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Admin Email"
                  type="email"
                  fullWidth
                  required
                  value={formData.adminEmail}
                  onChange={handleInputChange('adminEmail')}
                  error={!!errors.adminEmail}
                  helperText={errors.adminEmail || "For password recovery and notifications"}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Admin Phone Number"
                  type="tel"
                  fullWidth
                  value={formData.adminPhone}
                  onChange={handleInputChange('adminPhone')}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Typography variant="h6" gutterBottom sx={{
              display: 'block',
              mb: 2,
              mt: 4,
              fontWeight: 500,
              color: 'text.primary',
            }}>
              Account Creation
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Admin Username"
                  fullWidth
                  required
                  value={formData.adminUsername}
                  onChange={handleInputChange('adminUsername')}
                  error={!!errors.adminUsername}
                  helperText={errors.adminUsername || "Must be unique"}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Password"
                  type="password"
                  fullWidth
                  required
                  value={formData.password}
                  onChange={handleInputChange('password')}
                  error={!!errors.password}
                  helperText={errors.password || "Minimum 8 characters"}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Confirm Password"
                  type="password"
                  fullWidth
                  required
                  value={formData.confirmPassword}
                  onChange={handleInputChange('confirmPassword')}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Box sx={{ mt: 4 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                }
                sx={{ paddingBottom: '40px' }}
                label={
                  <Typography variant="body2">
                    By clicking here, I agree to the{' '}
                    <Link
                      to="#"
                      onClick={handlePolicyClick}
                      style={{ color: 'green', textDecoration: 'none' }}
                      onMouseEnter={(e) => { e.currentTarget.style.textDecoration = 'underline'; }}
                      onMouseLeave={(e) => { e.currentTarget.style.textDecoration = 'none'; }}
                    >
                      Privacy Policy
                    </Link>{' '}
                    and{' '}
                    <Link
                      to="#"
                      onClick={handleTermsClick}
                      style={{ color: 'green', textDecoration: 'none' }}
                      onMouseEnter={(e) => { e.currentTarget.style.textDecoration = 'underline'; }}
                      onMouseLeave={(e) => { e.currentTarget.style.textDecoration = 'none'; }}
                    >
                      Terms & Conditions
                    </Link>
                  </Typography>
                }
              />
              {errors.termsAgreed && (
                <Typography color="error" variant="caption" sx={{ display: 'block', ml: 2 }}>
                  {errors.termsAgreed}
                </Typography>
              )}
            </Box>

            {submitError && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <Typography color="error" variant="body2">
                  {submitError}
                </Typography>
              </Box>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  px: 4,
                  py: 1.5,
                  fontWeight: 'bold',
                  borderRadius: 2,
                  bgcolor: 'green',
                  '&:hover': {
                    bgcolor: 'darkgreen',
                  }
                }}
                size="large"
              >
                Register School
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>

      <Footer />

      <DocumentViewerModal
        open={privacyPolicyOpen}
        onClose={() => setPrivacyPolicyOpen(false)}
        documentUrl={documentUrls.privacyPolicy}
        title="Privacy Policy"
      />

      <DocumentViewerModal
        open={termsOpen}
        onClose={() => setTermsOpen(false)}
        documentUrl={documentUrls.termsAndConditions}
        title="Terms & Conditions"
      />

      <Dialog
        open={successDialogOpen}
        onClose={() => handleSuccessDialogClose()}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 1,
            overflow: 'hidden'
          }
        }}
      >
        <Box
          sx={{
            bgcolor: 'green',
            py: 3,
            px: 3,
            textAlign: 'center'
          }}
        >
          <CheckCircleIcon sx={{ fontSize: 48, color: 'white', mb: 1 }} />
          <Typography variant="h6" fontWeight="bold" color="white">
            Registration Successful
          </Typography>
        </Box>

        <DialogContent sx={{ py: 3, px: 3 }}>

          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0.5 }}>
              SCHOOL ID
            </Typography>
            <Box
              sx={{
                p: 1.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                bgcolor: '#f5f5f5',
                borderRadius: 1
              }}
            >
              <Typography variant="body2" fontFamily="monospace">
                {registeredSchoolId}
              </Typography>
              <Button
                size="small"
                onClick={() => handleCopy(registeredSchoolId, setSchoolIdCopied)}
                startIcon={schoolIdCopied ? <DoneIcon /> : <ContentCopyIcon />}
                sx={{ color: schoolIdCopied ? 'green' : 'text.secondary' }}
              >
                {schoolIdCopied ? 'Copied' : 'Copy'}
              </Button>
            </Box>
          </Box>

          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 0.5 }}>
              QUICK LOGIN LINK
            </Typography>
            <Box
              sx={{
                p: 1.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                bgcolor: '#f5f5f5',
                borderRadius: 1
              }}
            >
              <Typography
                variant="body2"
                fontFamily="monospace"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}
              >
                {`${window.location.origin}/login/${registeredSchoolId}`}
              </Typography>
              <Button
                size="small"
                onClick={() => handleCopy(`${window.location.origin}/login/${registeredSchoolId}`, setLoginLinkCopied)}
                startIcon={loginLinkCopied ? <DoneIcon /> : <ContentCopyIcon />}
                sx={{ color: loginLinkCopied ? 'green' : 'text.secondary', flexShrink: 0 }}
              >
                {loginLinkCopied ? 'Copied' : 'Copy'}
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              p: 2,
              bgcolor: '#f9f9f9',
              borderLeft: '3px solid green',
              borderRadius: 1,
              mt: 2
            }}
          >
            <Typography variant="body2" color="text.secondary">
              Please save this information for future login access.
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions sx={{ p: 2, borderTop: '1px solid #eee' }}>
          <Button
            variant="contained"
            onClick={() => handleSuccessDialogClose()}
            sx={{
              bgcolor: 'green',
              '&:hover': { bgcolor: 'darkgreen' },
              px: 3
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <CustomModal
        isModalVisible={emailVerificationModalOpen}
        onPress={() => setEmailVerificationModalOpen(false)}
        buttonText="OK"
        modalTitle="Verify your Email ID"
        text="Please check your email to proceed. We've sent a verification link to your email."
        icon={true}
      />
      <CustomModal
        isModalVisible={errorModalOpen}
        onPress={() => setErrorModalOpen(false)}
        buttonText="OK"
        modalTitle="Admin Already Exist"
        text={errorModalMessage}
        icon={true}
      />
    </>
  );
}