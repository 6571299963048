import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import footerlogo from '../../../assets/img/footer-logo.svg';
import linkedin from '../../../assets/img/linked-in-icon.svg';
import facebook from '../../../assets/img/facebook.png';

import instagram from '../../../assets/img/instagram-icon.svg';
import twitter from '../../../assets/img/x-icon.svg';
import appstore from '../../../assets/img/appstore-icon.svg';
import googlestore from '../../../assets/img/googleplay-icon.svg';
import './Footer.scss';

export default function Footer() {
  return (
    <main>
    <Box component="footer" className="footer-section">
      <Container
        sx={{
          maxWidth: { xs: '100%', sm: '95%', md: '1460px' },
        }}
      >
        <Box className="footer-item-list">
          <section>
          <Box>
            <Box component="img" src={footerlogo} alt="Logo" className="logo" />
            <Typography className='mb-1'>Upgrade your spelling skills today!</Typography>
            <Typography className="copyright mb-1">SpellingHero ©</Typography>
            <Box
              className="footer-section__email"
              display="flex"
              alignItems="center"
            >
              <svg
                width="18"
                height="14"
                viewBox="0 0 18 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="me-1"
              >
                <path
                  d="M2.33268 13.6654C1.87435 13.6654 1.48213 13.5023 1.15602 13.1762C0.829905 12.8501 0.666571 12.4576 0.666016 11.9987V1.9987C0.666016 1.54036 0.829349 1.14814 1.15602 0.822031C1.48268 0.49592 1.8749 0.332587 2.33268 0.332031H15.666C16.1243 0.332031 16.5168 0.495364 16.8435 0.822031C17.1702 1.1487 17.3332 1.54092 17.3327 1.9987V11.9987C17.3327 12.457 17.1696 12.8495 16.8435 13.1762C16.5174 13.5029 16.1249 13.6659 15.666 13.6654H2.33268ZM8.99935 7.83203L15.666 3.66536V1.9987L8.99935 6.16536L2.33268 1.9987V3.66536L8.99935 7.83203Z"
                  fill="#333333"
                />
              </svg>
              <Link
                href="mailto:support@spellinghero.co"
                className=" text-color-grey "
              >
                support@pragnatyx.com
              </Link>
            </Box>
          </Box>
          </section>
          <section>
          <Box>
            <Typography variant="h6" className="footer-section__title mb-12 company-info">
              Company info
            </Typography>
            <ul className="footer-section__nav_item">
              <li>
                <Link href="/about-us">About us</Link>
              </li>
              <li>
                <Link href="/privacy-policy">Privacy policy</Link>
              </li>
              <li>
                <Link href="/terms-of-service">Terms & conditions</Link>
              </li>
              <li>
                <Link href="/contact-us">Contact us</Link>
              </li>
            </ul>
          </Box>
          </section>
          <section>
          <Box>
            <Typography variant="h6" className="footer-section__title mb-12">
              Features
            </Typography>
            <ul className="footer-section__nav_item">
              <li>
                <Link href="/home">Home</Link>
              </li>
              <li>
                <Link href="/profile">My profile</Link>
              </li>
              <li>
                <Link href="/myProgress">My progress</Link>
              </li>
            </ul>
            {/* <ul className='links-footer-logo'>
              <li>
                <Typography className='mb-1'>Information coming soon</Typography>
              </li>
            </ul> */}
          </Box>
          </section>
          <section>
          <Box>
            <Typography variant="h6" className="footer-section__title mb-12">
              Join the spelling
            </Typography>
            <ul className="footer-section__nav_item">
              <li>
                <Link
                  href="https://www.facebook.com/spellingheroapp/"
                  target="_blank"
                  className=" "
                >
                  <ListItemIcon sx={{ minWidth: '28px' }} className="width-footer-nav">
                    <Box
                      component="img"
                      src={facebook}
                      alt="Facebook"
                      width="18px"
                    />
                  </ListItemIcon>
                  <span className="me-1" style={{whiteSpace:"nowrap"}}>Spelling Facebook</span>
                </Link>
              </li>
              <li>
                <Link
                  href="https://www.instagram.com/spellingheroapp/"
                  target="_blank"
                  className=" "
                >
                  <ListItemIcon sx={{ minWidth: '28px' }} className="width-footer-nav">
                    <Box
                      component="img"
                      src={instagram}
                      alt="Instagram"
                      width="18px"
                    />
                  </ListItemIcon>
                  <span className="me-1" style={{whiteSpace:"nowrap",marginLeft:"2px"}}>Spelling Instagram</span>
                </Link>
              </li>
              <li>
                <Link
                  href="https://x.com/spellingheroapp"
                  className=" "
                  target="_blank"
                >
                  <ListItemIcon sx={{ minWidth: '28px' }} className="width-footer-nav">
                    <Box
                      component="img"
                      src={twitter}
                      alt="Twitter"
                      width="18px"
                    />
                  </ListItemIcon>
                  <span className="me-1" style={{whiteSpace:"nowrap"}}>Spelling Twitter</span>
                </Link>
              </li>
            </ul>
          </Box>
          </section>
          <section>
          {/* <Box>
            <Typography variant="h6" className="footer-section__title mb-12">
              Get the spelling app
            </Typography>
            <ul className='links-footer-logo'>
              <li>
                <Link href="https://apps.apple.com/us/app/spelling-hero-fun-games/id6503145411" className="footer-section__appdownload d-flex" target="_blank">
                  <Box component="img" src={appstore} alt="App Store" />
                  <Box>
                    <Typography className="text-color-grey download-app">
                      Download on the
                    </Typography>
                    <Typography className="title font-600  text-color-grey ">
                      App Store
                    </Typography>
                  </Box>
                </Link>
              </li>

              <li>
                <Link href="https://play.google.com/store/apps/details?id=com.spellingheromobile.prod&pli=1" className="footer-section__appdownload d-flex" target="_blank" >
                  <Box component="img" src={googlestore} alt="Google Play" />
                  <Box>
                    <Typography className="text-color-grey download-app">
                      Get it on
                    </Typography>
                    <Typography className="title font-600 text-color-grey ">
                      Google Play
                    </Typography>
                  </Box>
                </Link>
              </li>
            </ul>
          </Box> */}
          <Box>
            <Typography variant="h6" className="footer-section__title mb-12">
              Our Patents in Progress
            </Typography>
            <ul className='links-footer-logo'>
              <li>
                <Typography className='mb-1'>Information coming soon</Typography>
              </li>
            </ul>
          </Box>
          </section>
        </Box>
      </Container>
    </Box>
    </main>
  );
}
