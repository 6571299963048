import * as React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { SyntheticEvent, useEffect } from 'react';
import './MemebershipTab.scss';
import LogoMember from '../../../assets/img/Logo-member.png';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { createCheckoutSession } from '../../stripe/createCheckoutSession';
import { authen } from '../../firebase/firebase';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function MembershipTab() {
  const [value, setValue] = React.useState(0);
  const [monthlySubscription, setMonthlySubscription] =
    React.useState<any>(null);
  const [quarterlySubscription, setquarterlySubscription] =
    React.useState<any>(null);
  const [yearlySubscription, setYearlySubscription] = React.useState<any>(null);
  const [isCheckoutLoading, setIsCheckoutLoading] = React.useState(false);
  const profile = useSelector((state: any) => state.userprofile);
  
  const disableSubscriptionButton = [
    "clever-library",
    "district-sso",
    "classlink-sso",
    "manual-csv",
  ].includes(profile?.user?.auth_type);

  const product = useSelector((state: any) => state.products);
  useEffect(() => {
    if (product) {
      product.forEach((element: any) => {
        if (element?.product?.name == 'Ad-Free Monthly') {
          setMonthlySubscription(element);
        }
        if (element?.product?.name == 'Ad-Free Quarterly') {
          setquarterlySubscription(element);
        }
        if (element?.product?.name == 'Ad-Free Yearly') {
          setYearlySubscription(element);
        }
      });
    }
  }, [product]);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleCheckoutSession = async (uid: string, priceId: string) => {
    const result = await createCheckoutSession(uid, priceId);
    if (result?.success == false) setIsCheckoutLoading(false);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        allowScrollButtonsMobile
        scrollButtons
        className="tab-starting member-starting"
      >
        <Tab className="member-1" label="Monthly" />
        <Tab className="member-2" label="Every 3 months" />
        <Tab className="member-3" label="Yearly" />
      </Tabs>
      <TabPanel value={value} index={0}>
        {monthlySubscription == null ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: 'primary.main',
              }}
            />
          </Box>
        ) : (
          <Box>
            <Box>
              <Typography
                className="ad-free-text"
                variant="body2"
                color="#333333"
                fontFamily="inter"
                textAlign="left"
                fontWeight="600"
                padding="0px !important"
                gutterBottom
              >
                {monthlySubscription?.product?.name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '12px',
                  alignItems: 'center',
                }}
              >
                <Typography
                  className="experience-text-font"
                  variant="body2"
                  color="#454545"
                  fontFamily="inter"
                  fontWeight="500"
                  gutterBottom
                >
                  {monthlySubscription?.product?.description}
                </Typography>
                <img
                  src={LogoMember}
                  alt="logo-membership"
                  className="logo-membership"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '30px !important',
                gap: '5px',
              }}
            >
              <Typography
                className="price-text"
                variant="body2"
                fontSize="30px !important"
                color="#333333"
                fontFamily="inter"
                fontWeight="700"
                gutterBottom
              >
                ${monthlySubscription?.price?.unit_amount / 100} USD
              </Typography>
              <Typography
                fontSize="16px !important"
                variant="body2"
                color="#454545"
                fontFamily="inter"
                fontWeight="500"
                lineHeight="1.2 !important"
              >
                Per<br></br>
                Month
              </Typography>
            </Box>
            {isCheckoutLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // height: '100vh',
                }}
              >
                <CircularProgress
                  size={40}
                  sx={{
                    color: 'primary.main',
                  }}
                />
              </Box>
            ) : (
              <Button
                className="btn btn-primary subscribe-btn"
                onClick={() => {
                  authen?.currentUser?.uid &&
                    handleCheckoutSession(
                      authen?.currentUser?.uid,
                      monthlySubscription?.priceId
                    );
                  setIsCheckoutLoading(true);
                }}
                disabled={disableSubscriptionButton}
              >
                Subscribe
              </Button>
            )}
          </Box>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {quarterlySubscription == null ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // height: '100vh',
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: 'primary.main',
              }}
            />
          </Box>
        ) : (
          <Box>
            <Box>
              <Typography
                className="ad-free-text"
                variant="body2"
                color="#333333"
                fontFamily="inter"
                textAlign="left"
                fontWeight="600"
                padding="0px !important"
                gutterBottom
              >
                {quarterlySubscription?.product?.name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '12px',
                  alignItems: 'center',
                }}
              >
                <Typography
                  className="experience-text-font"
                  variant="body2"
                  color="#454545"
                  fontFamily="inter"
                  fontWeight="500"
                  gutterBottom
                >
                  {quarterlySubscription?.product?.description}
                </Typography>
                <img
                  src={LogoMember}
                  alt="logo-membership"
                  className="logo-membership"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '30px !important',
                gap: '5px',
              }}
            >
              <Typography
                className="price-text"
                fontSize="30px !important"
                variant="body2"
                color="#333333"
                fontFamily="inter"
                fontWeight="700"
                gutterBottom
              >
                ${quarterlySubscription?.price?.unit_amount / 100} USD
              </Typography>
              <Typography
                fontSize="16px !important"
                variant="body2"
                color="#454545"
                fontFamily="inter"
                fontWeight="500"
                lineHeight="1.2 !important"
              >
                Every<br></br>3 Months
              </Typography>
            </Box>
            {isCheckoutLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // height: '100vh',
                }}
              >
                <CircularProgress
                  size={40}
                  sx={{
                    color: 'primary.main',
                  }}
                />
              </Box>
            ) : (
              <Button
                className="btn btn-primary subscribe-btn"
                onClick={() => {
                  authen?.currentUser?.uid &&
                    handleCheckoutSession(
                      authen?.currentUser?.uid,
                      quarterlySubscription?.priceId
                    );
                  setIsCheckoutLoading(true);
                }}
                disabled={disableSubscriptionButton}
              >
                Subscribe
              </Button>
            )}
          </Box>
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {' '}
        {yearlySubscription == null ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // height: '100vh',
            }}
          >
            <CircularProgress
              size={40}
              sx={{
                color: 'primary.main',
              }}
            />
          </Box>
        ) : (
          <Box>
            <Box>
              <Typography
                className="ad-free-text"
                variant="body2"
                color="#333333"
                fontFamily="inter"
                textAlign="left"
                fontWeight="600"
                padding="0px !important"
                gutterBottom
              >
                {yearlySubscription?.product?.name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '12px',
                  alignItems: 'center',
                }}
              >
                <Typography
                  className="experience-text-font"
                  variant="body2"
                  color="#454545"
                  fontFamily="inter"
                  fontWeight="500"
                  gutterBottom
                >
                  {yearlySubscription?.product?.description}
                </Typography>
                <img
                  src={LogoMember}
                  alt="logo-membership"
                  className="logo-membership"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '30px !important',
                gap: '5px',
              }}
            >
              <Typography
                className="price-text"
                fontSize="30px !important"
                variant="body2"
                color="#333333"
                fontFamily="inter"
                fontWeight="700"
                gutterBottom
              >
                ${yearlySubscription?.price?.unit_amount / 100} USD
              </Typography>
              <Typography
                fontSize="16px !important"
                variant="body2"
                color="#454545"
                fontFamily="inter"
                fontWeight="500"
                lineHeight="1.2 !important"
              >
                Per<br></br>
                Year
              </Typography>
            </Box>
            {isCheckoutLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  // height: '100vh',
                }}
              >
                <CircularProgress
                  size={40}
                  sx={{
                    color: 'primary.main',
                  }}
                />
              </Box>
            ) : (
              <Button
                className="btn btn-primary subscribe-btn"
                onClick={() => {
                  authen?.currentUser?.uid &&
                    handleCheckoutSession(
                      authen?.currentUser?.uid,
                      yearlySubscription?.priceId
                    );
                  setIsCheckoutLoading(true);
                }}
                disabled={disableSubscriptionButton}
              >
                Subscribe
              </Button>
            )}
          </Box>
        )}
      </TabPanel>
    </Box>
  );
}
