import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { authen } from '../../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { fetchWordData } from '../../Utils/firebase-services';

interface props {
  view: string;
}

interface WordDefinition {
  def?: string;
  [key: string]: any;
}
const ColumnGroupingTable = ({ view }: props) => {
  const profile = useSelector((state: any) => state.userprofile);
  
  const correctWords = profile?.user?.metrics?.last_words_correct;
  
  const correctWordArray = correctWords && correctWords.split('|');
  // const adUnitId = TestIds.ADAPTIVE_BANNER;
  const [user, setUser] = React.useState<any>();
  const questionDetailData = useSelector((state: any) => state.questiondetail);
  const [wordDefinitions, setWordDefinitions] = React.useState<{[key: string]: WordDefinition}>({});
  const [isLoading, setIsLoading] = React.useState(true);

  const parseIncorrectWordString = (input: string) => {
    return (
      input &&
      input.split('|').map((pair) => {
        const [key, value] = pair.split('=');
        return { key, value };
      })
    );
  };
  const incorrectWordArray = parseIncorrectWordString(
    profile?.user?.metrics?.last_words_incorrect
  );

  React.useEffect(() => {
    if (questionDetailData?.detail) {
      setWordDefinitions(questionDetailData.detail);
    }
  }, [questionDetailData]);

  React.useEffect(() => {
    const fetchMissingDefinitions = async () => {
      if (authen?.currentUser?.isAnonymous) {
        setIsLoading(false);
        return;
      }

      setIsLoading(true);
      const newDefinitions = { ...wordDefinitions };
      
      try {
        const uniqueWords = new Set<string>();
        
        if (incorrectWordArray) {
          incorrectWordArray.forEach(({ key }) => uniqueWords.add(key));
        }
        
        if (correctWordArray) {
          correctWordArray.forEach((word: string) => uniqueWords.add(word));
        }

        const wordsToFetch = Array.from(uniqueWords).filter(
          (word: string) => !newDefinitions[word]
        );

        if (wordsToFetch.length === 0) {
          setIsLoading(false);
          return;
        }

        const fetchPromises = wordsToFetch.map(async (word: string) => {
          const wordField = await fetchWordData(word);
          if (wordField) {
            newDefinitions[word] = {
              ...wordField,
            };
          }
          return { word, wordField };
        });

        await Promise.all(fetchPromises);
        setWordDefinitions(newDefinitions);
      } catch (error) {
        console.error('Error fetching definitions:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMissingDefinitions();
  }, [incorrectWordArray, correctWordArray, wordDefinitions]);

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(authen, (currentUser) => {
      setUser(currentUser); // Update the user state
    });
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <Paper sx={{ 
        width: '100%', 
        borderRadius: '14px', 
        boxShadow: 'none', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '200px',
        position: 'relative'
      }}>
        <CircularProgress />
      </Paper>
    );
  }

  return (
    <Paper sx={{ width: '100%', borderRadius: '14px', boxShadow: 'none' }}>
      {authen?.currentUser?.isAnonymous ? (
        <Typography
          sx={{
            fontWeight: '700',
            fontSize: '20px',
            fontFamily: 'Mulish',
          }}
          variant="body2"
          color="#333333"
          gutterBottom
        >
          Sign up to track your spelling history!
        </Typography>
      ) : view == '1' ? (
        <>
          {incorrectWordArray === undefined ||
          incorrectWordArray.length === 0 ? (
            <Typography
              sx={{
                fontWeight: '700',
                fontSize: '20px',
                fontFamily: 'Mulish',
              }}
              variant="body2"
              color="#333333"
              gutterBottom
            >
              Attempt spelling to see your history!
            </Typography>
          ) : (
            <>
              {' '}
              <Box
                sx={{
                  paddingBottom: '10px',
                  display: 'grid',
                  alignItems: 'center',
                  gridTemplateColumns: '30% 30% 40%',
                  gap: '10px',
                }}
                className="table-heading"
              >
                <Typography
                  sx={{
                    fontWeight: '700',
                    fontSize: '20px',
                    fontFamily: 'Mulish',
                  }}
                  variant="body2"
                  color="#333333"
                  gutterBottom
                >
                  Your attempted spelling
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '700',
                    fontSize: '20px',
                    fontFamily: 'Mulish',
                  }}
                  variant="body2"
                  color="#333333"
                  gutterBottom
                >
                  Correct spelling
                </Typography>
                <Typography
                  sx={{
                    fontWeight: '700',
                    fontSize: '20px',
                    fontFamily: 'Mulish',
                  }}
                  variant="body2"
                  color="#333333"
                  gutterBottom
                >
                  Meaning
                </Typography>
              </Box>
              <TableContainer
                className="table-scrolling"
                sx={{
                  maxHeight: 440,
                  scrollbarWidth: 'thin',
                  borderRadius: '8px',
                  border: '3.7px solid #878787',
                }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ borderRadius: '14px' }}
                >
                  <TableHead>{/* Table Head content */}</TableHead>

                  {view == '1' && (
                    <TableBody>
                      {incorrectWordArray &&
                        incorrectWordArray.map(({ key, value }, index) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={key}
                          >
                            <TableCell
                              className="first-cell"
                              sx={{
                                width: '30%',
                                borderRight: ' 1.85px solid #CCCCCC',
                                color: '#F14249',
                                fontWeight: '700',
                                fontSize: { sm: '20px', md: '20px' },
                                fontFamily: 'Mulish',
                              }}
                            >
                              {!authen?.currentUser?.isAnonymous ? value : '--'}
                            </TableCell>
                            <TableCell
                              className="second-cell"
                              sx={{
                                width: '30%',
                                borderRight: ' 1.85px solid #CCCCCC',
                                color: '#2A9235',
                                fontWeight: '700',
                                fontSize: { sm: '20px', md: '20px' },
                                fontFamily: 'Mulish',
                              }}
                            >
                              {!authen?.currentUser?.isAnonymous ? key : '--'}
                            </TableCell>
                            <TableCell
                              className="third-cell"
                              sx={{
                                width: '40%',
                                color: '#333333',
                                fontWeight: '500',
                                fontSize: { sm: '18px', md: '18px' },
                                fontFamily: 'Mulish',
                              }}
                            >
                              {!authen?.currentUser?.isAnonymous
                                ? wordDefinitions[key]?.def || 'Definition not available'
                                : '--'}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )}
                  {/* {view == '2' && (
                    <TableBody>
                      {correctWordArray &&
                        correctWordArray.map((word: string, index: number) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell
                              className="first-cell"
                              sx={{
                                borderRight: ' 1.85px solid #CCCCCC',
                                color: '#2A9235',
                                fontWeight: '700',
                                fontSize: { sm: '20px', md: '20px' },
                                fontFamily: 'Mulish',
                              }}
                            >
                              {!authen?.currentUser?.isAnonymous ? word : '--'}
                            </TableCell>
                            <TableCell
                              className="second-cell"
                              sx={{
                                color: '#2A9235',
                                fontWeight: '700',
                                fontSize: { sm: '20px', md: '20px' },
                                fontFamily: 'Mulish',
                              }}
                            >
                              {!authen?.currentUser?.isAnonymous ? word : '--'}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  )} */}
                </Table>
              </TableContainer>
            </>
          )}
        </>
      ) : (
        view == '2' && (
          <>
            {correctWordArray === undefined || correctWordArray.length === 0 ? (
              <Typography
                sx={{
                  fontWeight: '700',
                  fontSize: '20px',
                  fontFamily: 'Mulish',
                }}
                variant="body2"
                color="#333333"
                gutterBottom
              >
                Attempt spelling to see your history!
              </Typography>
            ) : (
              <>
                {' '}
                <Box
                  sx={{
                    paddingBottom: '10px',
                    display: 'grid',
                    alignItems: 'center',
                    gridTemplateColumns: '30% 30% 40%',
                    gap: '10px',
                  }}
                  className="table-heading"
                >
                  <Typography
                    sx={{
                      fontWeight: '700',
                      fontSize: '20px',
                      fontFamily: 'Mulish',
                    }}
                    variant="body2"
                    color="#333333"
                    gutterBottom
                  >
                    Your attempted spelling
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '700',
                      fontSize: '20px',
                      fontFamily: 'Mulish',
                    }}
                    variant="body2"
                    color="#333333"
                    gutterBottom
                  >
                    Correct spelling
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '700',
                      fontSize: '20px',
                      fontFamily: 'Mulish',
                    }}
                    variant="body2"
                    color="#333333"
                    gutterBottom
                  >
                    Meaning
                  </Typography>
                </Box>
                <TableContainer
                  className="table-scrolling"
                  sx={{
                    maxHeight: 440,
                    scrollbarWidth: 'thin',
                    borderRadius: '8px',
                    border: '3.7px solid #878787',
                  }}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{ borderRadius: '14px' }}
                  >
                    <TableHead>{/* Table Head content */}</TableHead>

                    {/* {view == '1' && (
                      <TableBody>
                        {incorrectWordArray &&
                          incorrectWordArray.map(({ key, value }, index) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={key}
                            >
                              <TableCell
                                className="first-cell"
                                sx={{
                                  borderRight: ' 1.85px solid #CCCCCC',
                                  color: '#F14249',
                                  fontWeight: '700',
                                  fontSize: { sm: '20px', md: '20px' },
                                  fontFamily: 'Mulish',
                                }}
                              >
                                {!authen?.currentUser?.isAnonymous
                                  ? value
                                  : '--'}
                              </TableCell>
                              <TableCell
                                className="second-cell"
                                sx={{
                                  color: '#2A9235',
                                  fontWeight: '700',
                                  fontSize: { sm: '20px', md: '20px' },
                                  fontFamily: 'Mulish',
                                }}
                              >
                                {!authen?.currentUser?.isAnonymous ? key : '--'}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    )} */}
                    {view == '2' && (
                      <TableBody>
                        {correctWordArray &&
                          correctWordArray.map(
                            (word: string, index: number) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index}
                            >
                              <TableCell
                                className="first-cell"
                                sx={{
                                  width: '30%',
                                  borderRight: ' 1.85px solid #CCCCCC',
                                  color: '#2A9235',
                                  fontWeight: '700',
                                  fontSize: { sm: '20px', md: '20px' },
                                  fontFamily: 'Mulish',
                                }}
                              >
                                {!authen?.currentUser?.isAnonymous
                                ? word
                                : '--'}
                              </TableCell>
                              <TableCell
                                className="second-cell"
                                sx={{
                                  width: '30%',
                                  borderRight: ' 1.85px solid #CCCCCC',
                                  color: '#2A9235',
                                  fontWeight: '700',
                                  fontSize: { sm: '20px', md: '20px' },
                                  fontFamily: 'Mulish',
                                }}
                              >
                                {!authen?.currentUser?.isAnonymous ? word : '--'}
                              </TableCell>
                              <TableCell
                                className="third-cell"
                                sx={{
                                  width: '40%',
                                  color: '#333333',
                                  fontWeight: '500',
                                  fontSize: { sm: '18px', md: '18px' },
                                  fontFamily: 'Mulish',
                                }}
                              >
                                {!authen?.currentUser?.isAnonymous
                                  ? wordDefinitions[word]?.def || 'Definition not available'
                                  : '--'}
                              </TableCell>
                            </TableRow>
                          )
                          )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )
      )}
    </Paper>
  );
};
export default ColumnGroupingTable;
